import axios from "axios";
import {addTokenHeader, refreshToken, TOKEN_KEY} from "./auth_actions";
import {CUR_ORG} from "./session_constants";

const Config = require('Config')

export async function fetchTemplates(errorCallback) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/signature/templates/${sessionStorage.getItem(CUR_ORG)}/list`;
        const response = await axios.get(url, addTokenHeader()).catch((error) => errorCallback(error));
        return response.data;
    }
}
export async function fetchOrgTemplates(orgId, errorCallback) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/signature/templates/${orgId}/list`;
        const response = await axios.get(url, addTokenHeader()).catch((error) => errorCallback(error));
        return response.data;
    }
}
export async function fetchAvailableTemplates(errorCallback) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/signature/templates/list`;
        const response = await axios.get(url, addTokenHeader()).catch((error) => errorCallback(error));
        return response.data;
    }
}

export async function addSignatures(signaturesPurchased){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        let url = `${Config.taskServerUrl}/signatures/bought/${signaturesPurchased}`;
        console.log('Adding signatures');
        axios.post(url, {}, addTokenHeader());
    }
}
