/**
 * Created by pshivaraman on 3/29/18.
 */

export const CUR_ORG = 'curOrg';
export const CURR_LANDING_PAGE = 'CURR_LANDING_PAGE';
export const ISSUE_TO_UPLOAD = 'ISSUE_TO_UPLOAD';
export const CON_IN_THE_WORKS = 'ContractInTheWorks';
export const FAC_FOR_ORG_CON_CREATE = 'OrgFacilitiesForContractCreation';

export const SYS_LANDING_PATH = '/sys/dash';
export const ORG_LANDING_PATH = '/org/list';