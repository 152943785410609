/**
 * Created by shivaraman on 8/9/17.
 */

import {addTokenHeader, refreshToken, REPORTING_PASS_CODE, TOKEN_KEY} from "./auth_actions";
import axios from "axios";
import {
    FETCH_ACCESS_REQS,
    FETCH_AGENCIES,
    FETCH_DISTRIBUTIONS,
    FETCH_FACILITY_LEVELS,
    FETCH_TIMEZONES
} from "./action_constants";

// To get the configuration so we can load properties
const Config = require('Config');

export function getAgencies(callback) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/fieldValues/agency/list`;
        axios.get(url, addTokenHeader()).then(callback);
    }
}

export function fetchAgencies() {
    return function (dispatch) {
        refreshToken(dispatch);
        getAgencies((response) => {
            dispatch({
                type: FETCH_AGENCIES,
                payload: response,
            });
        });
    }
}

export function getFacilityLevels(callback) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/fieldValues/facilityLevel/list`;
        axios.get(url, addTokenHeader()).then(callback);
    }
}

export function fetchFacilityLevels() {
    return function (dispatch) {
        refreshToken(dispatch);
        getAgencies((response) => {
            dispatch({
                type: FETCH_FACILITY_LEVELS,
                payload: response,
            });
        });
    }
}

export function getTimezones(callback) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/fieldValues/timezone/list`;
        axios.get(url, addTokenHeader()).then(callback);
    }
}

export function fetchTimezones() {
    return function (dispatch) {
        refreshToken(dispatch);
        getTimezones((response) => {
            dispatch({
                type: FETCH_TIMEZONES,
                payload: response
            })
        })
    }
}

export function getDocTypes(callback) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/fieldValues/docType/list/all`;
        axios.get(url, addTokenHeader()).then(callback);
    }
}

export function getAssetManagementStatus(callback) {
    const url = `${Config.personServerUrl}/fieldValues/assetManagementStatus/list/all`;
    axios.get(url, addTokenHeader()).then(callback);
}

export function getPostForeignTravelQuestionTypes() {
    const url = `${Config.personServerUrl}/person/selfReporting/fieldValues/postForeignTravelQuestionType/list/all`;
    return axios.get(url, addTokenHeader());
}

export function getForeignContactQuestionTypes() {
    const url = `${Config.personServerUrl}/person/selfReporting/fieldValues/foreignContactQuestionType/list/all`;
    return axios.get(url, addTokenHeader());
}

export function getDocTypesByEntityType(entityType, callback) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/fieldValues/docType/list/byEntityType/${entityType}`;
        axios.get(url, addTokenHeader()).then(callback);
    }
}

export function getComplianceDocTypesForSubject(callback) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/fieldValues/docType/subject/complianceList`;
        axios.get(url, addTokenHeader()).then(callback);
    }
}

export function getReqDocsByEntityType(entityType) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/fieldValues/docType/req/byEntityType/${entityType}`;
        return new Promise((resolve, reject) => {
            axios.get(url, addTokenHeader()).then(resolve);
        });
    }
}

export function getAccessReqsByAccessor(accessor, callback) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        // TODO: Move this to MongoDB in the future
        const response = accessor === "contract" ? {
            data: ([
                {id: "com-sec-info", name: "Communications Security Information", accessors: ["contract"]},
                {id: "restricted-data", name: "Restricted Data", accessors: ["contract"]},
                {id: "nuclear-design-info", name: "Critical Nuclear Design Information", accessors: ["contract"]},
                {id: "former-restricted-data", name: "Formerly Restricted Data", accessors: ["contract"]},
                {id: "sci", name: "Sensitive Intelligence Information (SCI)", accessors: ["contract"]},
                {id: "non-sci", name: "Non-Sensitive Intelligence Information", accessors: ["contract"]},
                {id: "spec-access-info", name: "Special Access Information", accessors: ["contract"]},
                {id: "nato-info", name: "NATO Information", accessors: ["contract"]},
                {id: "foreign-govt-info", name: "Foreign Government Information", accessors: ["contract"]},
                {id: "accm-info", name: "Alternative Compensatory Control Measures (ACCM) Information", accessors: ["contract"]},
                {id: "cui-info", name: "Controlled Unclassified Information (CUI)", accessors: ["contract"]},
                {id: "other", name: "Other (please specify below)", accessors: ["contract", "contractor"]}
            ]).sort((a, b) => a.name.localeCompare(b.name))
        } : accessor === "contractor" ? {
            data: ([
                {
                    id: "access-classified-info",
                    name: "Classified Information Only At Another Contractor's Facility or Government Activity",
                    accessors: ["contractor"]
                },
                {id: "classified-docs-only", name: "Receive Classified Documents Only", accessors: ["contractor"]},
                {
                    id: "recv-classified-material",
                    name: "Receive and Generate Classified Material",
                    accessors: ["contractor"]
                },
                {
                    id: "classified-hardware",
                    name: "Fabricate, Modify, or Store Classified Hardware",
                    accessors: ["contractor"]
                },
                {id: "perf-svcs-only", name: "Perform Services Only", accessors: ["contractor"]},
                {
                    id: "outside-classified-info",
                    name: "Classified Information Outside U.S., Puerto Rico, U.S. Possessions and Trust Territories",
                    accessors: ["contractor"]
                },
                {
                    id: "use-dtic-svcs",
                    name: "Authorized to Use Services of DTIC or Other Secondary Distribution Center",
                    accessors: ["contractor"]
                },
                {id: "req-comsec-acct", name: "Require ComSec Account", accessors: ["contractor"]},
                {id: "tempest-reqs", name: "Have Tempest Requirements", accessors: ["contractor"]},
                {id: "opsec-reqs", name: "Have Operations Security (OpSec) Requirements", accessors: ["contractor"]},
                {id: "def-courier-svc", name: "Use Defence Courier Service", accessors: ["contractor"]},
                {id: "rsg-cui", name: "Receive, Store or Generate CUI", accessors: ["contractor"]},
                {id: "other", name: "Other (please specify below)", accessors: ["contract", "contractor"]},
            ]).sort((a, b) => a.name.localeCompare(b.name))
        } : {data: []};
        callback(response);
    }
}

export function fetchAccessReqsByAccessor(accessor) {
    return function (dispatch) {
        refreshToken(dispatch);
        getAccessReqsByAccessor(accessor, (response) => {
            dispatch({
                type: FETCH_ACCESS_REQS,
                payload: response
            })
        })
    }
}

export function getDistributions(callback) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const response = {
            data:([
                {id: "contractor", name: "Contractor"},
                {id: "sub-contractor", name: "Sub-Contractor"},
                {id: "smo", name: "Cognizant Security Office for Prime and Sub-Contractor"},
                {id: "overseas-admin", name: "U.S. Activity Responsible for Overseas Security Administration"},
                {id: "admin-contact-officer", name: "Administrative Contacting Officer"},
                {id: "others", name: "Others as Necessary"},
            ]).sort((a, b) => a.name.localeCompare(b.name))
        };
        callback(response);
    }
}

export function fetchDistributions() {
    return function (dispatch) {
        refreshToken(dispatch);
        getDistributions((response) => {
            dispatch({
                type: FETCH_DISTRIBUTIONS,
                payload: response
            })
        })
    }
}

export function getEmployeeTypes() {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/fieldValues/employeeType/list/all`;
        return axios.get(url, addTokenHeader());
    }
}

export async function getMediaTypesForDocControl() {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/fieldValues/docControl/mediaTypes/list/all`;
        const response = await axios.get(url, addTokenHeader());
        return response.data;
    }

}

export async function getClearanceOrgs() {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/fieldValues/clearanceOrg/list/all`;
        const response = await axios.get(url, addTokenHeader());
        return response.data;
    }

}

export async function getDestructionTypesForDocControl() {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/fieldValues/docControl/destructionTypes/list/all`;
        const response = await axios.get(url, addTokenHeader());
        return response.data;
    }

}

export async function getContractStatusTypes() {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/fieldValues/contractStatus/list/all`;
        const response = await axios.get(url, addTokenHeader());
        return response.data;
    }

}

export function getBriefingLevels() {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/fieldValues/briefingLevel/list/all`;
        return axios.get(url, addTokenHeader());
    }
}

export function getEntityTypes() {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/fieldValues/entityType/list/all`;
        return axios.get(url, addTokenHeader());
    }
}

export function getCitizenshipAcquiredTypes() {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/fieldValues/citizenshipAcquiredTypes/list/all`;
        return axios.get(url, addTokenHeader());
    }
}

export function getJwtExpirationTime() {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/fieldValues/jwtExpirationTime`;
        return axios.get(url, addTokenHeader());
    }
}

export function getSelfReportingExpirationTime() {
    if (sessionStorage.getItem(REPORTING_PASS_CODE)) {
        const url = `${Config.personServerUrl}/person/selfReporting/fieldValues/selfReportingExpirationTime`;
        return axios.get(url, addTokenHeader());
    }
}

export function getInvalidationTypesByReportableType(reportableType) {
    return new Promise((resolve, reject) => {
        const url = `${Config.personServerUrl}/person/selfReporting/fieldValues/invalidationTypes/byReportableType/${reportableType}`;
        axios.get(url).then((response) => {
            resolve(response.data)
        }).catch(reject);
    })
}

export async function getNotificationTypes() {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/fieldValues/NotificationType/list/all`;
        const response = await axios.get(url, addTokenHeader());
        return response.data;
    } 
}