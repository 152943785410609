/**
 * Created by shivaraman on 5/17/17.
 */

// Organization
export const CREATE_ORG = 'CREATE_ORG';
export const UPDATE_ORG = 'UPDATE_ORG';
export const DELETE_ORG = 'DELETE_ORG';
export const FETCH_ORGS_BY_USER = 'FETCH_ORGS_BY_USER';
export const FETCH_ORGS = 'FETCH_ORGS';
export const FETCH_MANAGED_ORGS = 'FETCH_MANAGED_ORGS';
export const FETCH_ORGS_SEARCH = 'FETCH_ORGS_SEARCH';
export const FETCH_ORG = 'FETCH_ORG';
export const FETCH_CUR_ORG = 'FETCH_CUR_ORG';

//Report Types
export const SUBJECT_REPORT = 'Subject Report (General)';
export const SUBJECT_COMPLIANCE_REPORT = 'Subject Compliance Report';
export const SUBJECT_EQIP_STATUS_REPORT = 'Subject Eqip Status Report';
export const SUBJECT_INVALID_EMAIL_REPORT = 'Subject Invalid Email Report';
export const ASSET_REPORT = 'Asset Report';
export const DOC_CONTROL_REPORT = 'Document Control Report';
export const FOREIGN_TRAVEL_REPORT = 'Foreign Travel Report';
export const REQD_DOCS_DUE_REPORT = 'Document Status Report'
export const CUSTOM_REPORT = 'Create Custom Report';

// Visits
export const VISITS_FETCHED='VISITS_FETCHED';
export const VISIT_APPROVED='VISIT_APPROVED';
export const VISIT_REJECTED='VISIT_REJECTED';
export const VISIT_ISSUES_FETCHED='VISIT_ISSUES_FETCHED';
export const VISIT_ISSUE_FETCHED='VISIT_ISSUE_FETCHED';

// Organization -> Documents
export const FETCH_ORG_DOCS = 'FETCH_ORG_DOCS';
export const UPLOAD_ORG_DOCS = 'UPLOAD_ORG_DOCS';
export const DELETE_ORG_DOC = 'DELETE_ORG_DOC';

// Organization -> Contracts
export const FETCH_ORG_CONTRACTS = 'FETCH_ORG_CONTRACTS';
export const FETCH_ORG_CONTRACTS_BY_SMO = 'FETCH_ORG_CONTRACTS_BY_SMO';
export const FETCH_ORG_CONTRACT = 'FETCH_ORG_CONTRACT';
export const CREATE_ORG_CONTRACT = 'CREATE_ORG_CONTRACT';
export const UPDATE_ORG_CONTRACT = 'UPDATE_ORG_CONTRACT';
export const DELETE_ORG_CONTRACT = 'DELETE_ORG_CONTRACT';
export const PARENT_TASK_ORDER_CONTRACT = 'PARENT_TASK_ORDER_CONTRACT';

// Organization -> Facilities
export const FETCH_ORG_FACILITIES = 'FETCH_ORG_FACILITIES';
export const FETCH_ORG_FACILITY = 'FETCH_ORG_FACILITY';
export const CREATE_ORG_FACILITY = 'CREATE_ORG_FACILITY';
export const UPDATE_ORG_FACILITY = 'UPDATE_ORG_FACILITY';
export const DELETE_ORG_FACILITY = 'DELETE_ORG_FACILITY';
export const FETCH_FACILITIES = 'FETCH_FACILITIES';
export const SEARCH_FACILITIES = 'SEARCH_FACILITIES';

// Organization -> Tasks
export const FETCH_ORG_TASKS = 'FETCH_ORG_TASKS';
export const FETCH_TASKS = 'FETCH_TASKS';
export const FETCH_TASK = 'FETCH_TASK';
export const CREATE_TASK = 'CREATE_TASK';
export const TASK_CANCELLED = 'TASK_CANCELLED';
export const TASK_REJECTED = 'TASK_REJECTED';
export const TASK_COMPLETED = 'TASK_COMPLETED';
export const FETCH_REPORTABLE_ACTIVITY = 'FETCH_REPORTABLE_ACTIVITY';

// Field Values
export const FETCH_AGENCIES = 'FETCH_AGENCIES';
export const FETCH_FACILITY_LEVELS = 'FETCH_FACILITY_LEVELS';
export const FETCH_TIMEZONES = 'FETCH_TIMEZONES';
export const FETCH_ACCESS_REQS = 'FETCH_ACCESS_REQS';
export const FETCH_DISTRIBUTIONS = 'FETCH_DISTRIBUTIONS';
export const FETCH_ENTITY_TYPES = 'FETCH_ENTITY_TYPES';

// Documents
export const FETCH_DOCUMENTS = 'FETCH_DOCUMENTS';
export const FETCH_DOCUMENTS_BY_IDS = 'FETCH_DOCUMENTS_BY_IDS';
export const SEARCH_DOCUMENTS = 'SEARCH_DOCUMENTS';
export const FETCH_DOCUMENT = 'FETCH_DOCUMENT';
export const DOCUMENT_UPDATED = 'DOCUMENT_UPDATED';
export const CREATE_DOCUMENT = 'CREATE_DOCUMENTS';
export const CREATE_DOCUMENT_REV = 'CREATE_DOCUMENT_REVISION';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';

// Bulk upload documents
export const BEGIN_BULK_DOC_UPLOAD = 'BEGIN_BULK_DOC_UPLOAD';
export const DOCS_BULK_UPLOADED = 'DOCS_BULK_UPLOADED';

// Person
export const FETCH_PERSONS = 'FETCH_PERSONS';
export const FETCHED_PERSONS_USER_CREATION = 'FETCHED_PERSONS_USER_CREATION';
export const FETCHED_PERSONS_FOR_REMOVAL = 'FETCHED_PERSONS_FOR_REMOVAL';
export const SEARCH_PERSONS = 'SEARCH_PERSONS';
export const CREATE_PERSON = 'CREATE_PERSON';
export const UPDATE_PERSON = 'UPDATE_PERSON';
export const FETCH_PERSON_BY_ID = 'FETCH_PERSON_BY_ID';
export const CSV_UPLOAD = 'CSV_UPLOAD';
export const CSV_UPLOAD_ASSETS = 'CSV_UPLOAD_ASSETS';
export const CSV_UPLOAD_DOC_CONTROLS = 'CSV_UPLOAD_DOC_CONTROLS';
export const CREATE_PERSONS = 'CREATE_PERSONS';
export const REMOVE_PERSONS = 'REMOVE_PERSONS';
export const SELF_REPORTING_DONE = 'SELF_REPORTING_DONE';
export const SELF_REPORTING_AUTH_DONE = 'SELF_REPORTING_AUTH_DONE';
export const SUBJECT_REVIEW_INITIATED = 'SUBJECT_REVIEW_INITIATED';
export const SUBJECT_CHANGES_CONFIRMED = 'SUBJECT_CHANGES_CONFIRMED';
export const FETCH_SUBJECT_REPORTABLES = 'FETCH_SUBJECT_REPORTABLES';
export const EMAIL_CHECK_RESPONSE = 'EMAIL_CHECK_RESPONSE';

// Mass Email Options
export const MASS_EMAIL_FOR_ALL_ORG_ADMINS='Broadcast to all Org Administrators';
export const MASS_EMAIL_FOR_ALL='Broadcast to everyone';

// Reports
export const REPORT_GENERATED = 'REPORT_GENERATED';

// User
export const FETCH_USERS = 'FETCH_USERS';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const FETCH_USER_BY_ID = 'FETCH_USER_BY_ID';
export const FETCH_USER_BY_PERSON = 'FETCH_USER_BY_PERSON';
export const DELETE_USER = 'DELETE_USER';

export const CONTACT_EMAIL_SENT = 'CONTACT_EMAIL_SENT';
// Agency
// export const FETCH_AGENCIES = 'FETCH_AGENCIES';

// Smo
export const FETCH_SMOS = 'FETCH_SMOS';
export const FETCH_SMOS_BY_ORG = 'FETCH_SMOS_BY_ORG';
export const FETCH_SMO_DOCUMENTS = 'FETCH_SMO_DOCS';
export const CREATE_SMO = 'CREATE_SMO';
export const FETCH_SMO_BY_ID = 'FETCH_SMO_BY_ID';
export const UPLOAD_SMO_DOCUMENTS = 'UPLOAD_SMO_DOCS';
export const DELETE_SMO_DOCUMENT = 'DELETE_SMO_DOC';
export const FETCH_CONTRACTS = 'FETCH_CONTRACTS';
export const FETCH_AGENCY_CONTRACT_BY_ID = 'FETCH_AGENCY_CONTRACT_BY_ID';
export const CREATE_AGENCY_CONTRACT = 'CREATE_AGENCY_CONTRACT';
export const UPDATE_AGENCY_CONTRACT = 'UPDATE_AGENCY_CONTRACT';

// Smo/Agency
export const FETCH_SMO_AGENCIES = 'FETCH_SMO_AGENCIES';
export const FETCH_SMO_AGENCY_BY_ID = 'FETCH_SMO_AGENCY_BY_ID';
export const CREATE_SMO_AGENCY = 'CREATE_SMO_AGENCY';
export const UPDATE_SMO_AGENCY = 'UPDATE_SMO_AGENCY';

// actions - login related
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const INVALID_TOKEN = 'TOKEN_EXPIRED_OR_INVALID';

// Callback success/failure text
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";

// Messages
export const SHOW_MESSAGE = 'SHOW_MESSAGE';

export const EntityType = {
    ORG: "ORG",
    SUBJECT: "SUBJECT",
    CONTRACT: "CONTRACT",
    FACILITY: "FACILITY"
};

export const ComplianceType = {
    COMPLIANT: 'COMPLIANT',
    NON_COMPLIANT: 'NON_COMPLIANT',
    ALL: 'ALL'
};

export const FETCH_ENTITIES_FOR_COMPLIANCE = 'FETCH_ENTITIES_FOR_COMPLIANCE';
