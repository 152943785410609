/**
 * Created by pshivaraman on 6/7/18.
 */

import {addTokenHeader, refreshToken, SUBJECT_ID, TOKEN_KEY} from "./auth_actions";
import {onError} from "./error_actions";
import axios from "axios";
import {getDataFromDocs, getDataFromDocsAsync} from "./doc_actions";
import {CREATE_ORG_CONTRACT, FETCH_ORG_CONTRACT, FETCH_ORG_CONTRACTS, UPDATE_ORG_CONTRACT} from "./action_constants";
import {CUR_ORG} from "./session_constants";
import {getActiveOrgPersons} from "./subject_actions";
import {getAccessReqsByAccessor, getDistributions, getFacilityLevels} from "./fieldValue_actions";
import {DateUtils} from "./common_utils";
import {getOrgFacilities} from "./facility_actions";

// To get the configuration so we can load properties
const Config = require('Config');

/**
 * Extracts underlying Contract from ContractUI and combines the two.
 * @param contract ContractUI object from axios
 * @returns {*} Combined Contract and ContractUI
 */
export function getContractObj(contract) {
    if (contract) {
        return contract.contract ? {...contract.contract, ...contract} : contract;
    }
    return null;
}

export function createOrgContract(contract, callBack) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/contract/create`;
            const contractUIObj = {
                contract: contract
            };
            axios.post(url, contractUIObj, addTokenHeader()).then((response) => {
                callBack();

                dispatch({
                    type: CREATE_ORG_CONTRACT,
                    payload: response
                });
            }); // when promise returns then we call the callback
        }
    }
}

export async function uploadDD254Doc(docs) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/contract/upload`;
        const documents = await getDataFromDocsAsync(docs);
        let reqObj = {
            data: documents[0].data
        }

        const response = await axios.post(url,reqObj,  addTokenHeader());
        return response.data;
    }
}
export function updateOrgContract(contract, contractId, callBack) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/contract/update`;

            const contractObj = {
                contract: contract
            };

            contractObj.contract.id = contractId;

            getDataFromDocs(contract.documents, (docs) => {
                contractObj.documents = docs.map(doc => {
                    return {
                        name: doc.name,
                        revisions: [
                            {
                                fileName: doc.name,
                                fileType: doc.type,
                                data: doc.data,
                                uploader: sessionStorage.getItem(SUBJECT_ID)
                            }
                        ]
                    };
                });

                axios.post(url, contractObj, addTokenHeader()).then((response) => {
                    callBack();

                    dispatch({
                        type: UPDATE_ORG_CONTRACT,
                        payload: response
                    });
                });
            });
        }
    }
}

export function fetchOrgContract(contractId) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/contract/${contractId}`;
            axios.get(reqUrl, addTokenHeader()).then((contractResp) => {
                const contract = contractResp.data;
                if (contract.endDate) {
                    contract.fullEndDate = contract.endDate;
                    contract.endDate = DateUtils.toYYYYMMDD(contract.endDate);
                }
                if (contract.specDate) {
                    contract.specDate = DateUtils.toYYYYMMDD(contract.specDate);
                }
                if (contract.finalReqDate) {
                    contract.finalReqDate = DateUtils.toYYYYMMDD(contract.finalReqDate);
                }

                getActiveOrgPersons(personsResp => {
                    contract.avail_subjects = personsResp.data;
                    getOrgFacilities(sessionStorage.getItem(CUR_ORG)).then((facResp) => {
                        contract.avail_facilities = facResp.data;
                        getFacilityLevels(levelsResp => {
                            contract.levels = levelsResp.data;
                            getAccessReqsByAccessor("contract", accessReqsResp => {
                                contract.avail_access_reqs = accessReqsResp.data;
                                getAccessReqsByAccessor("contractor", contractorAccessReqResp => {
                                    contract.avail_contractor_access_reqs = contractorAccessReqResp.data;
                                    getDistributions(distResp => {
                                        contract.avail_distributions = distResp.data;

                                        contractResp.data = getContractObj(contract);
                                        dispatch({
                                            type: FETCH_ORG_CONTRACT,
                                            payload: contractResp
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        }
    }
}

export async function getActiveContractsForDocControl() {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/contract/forDocControl/list`;
        const response = await axios.get(url, addTokenHeader());
        return response.data;
    }
}

export async function getContractTaskOrders(contractId) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/contract/${contractId}/taskOrderList`;
        const response = await axios.get(url, addTokenHeader());
        return response.data;
    }
}

export async function getActiveOrgContractsAsync(errorCallback) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        // go to smo service and get the contracts
        const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/contract/active/list`;
        const response = await axios.get(url, addTokenHeader()).catch(errorCallback);
        return response.data;
    }
}

export function getOrgContracts(orgId) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        // go to smo service and get the contracts
        const url = `${Config.personServerUrl}/org/${orgId}/contract/list`;
        return axios.get(url, addTokenHeader());
    }
}

export function fetchOrgContracts(orgId) {
    return function (dispatch) {
        refreshToken(dispatch);
        getOrgContracts(orgId).then(response => {
            response.data = response.data.map(getContractObj);

            dispatch({
                type: FETCH_ORG_CONTRACTS,
                payload: response
            });
        });
    }
}

export function deleteContract(contractId, callBack) {
    console.log(`Contract ${contractId} is going to be deleted`)
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/contract/${contractId}/remove`;
            console.log(`url for delete is ${url}`)
            axios.delete(url, addTokenHeader()).then(response => {
                callBack()
            })
            console.log(`Contract ${contractId} is deleted`)
        }
    }
}