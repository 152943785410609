/**
 * Created by shivaraman on 6/12/17.
 */
import axios from 'axios';

import {
    CREATE_USER,
    DELETE_DOCUMENT, DELETE_USER,
    FETCH_USER_BY_ID,
    FETCH_USER_BY_PERSON,
    FETCH_USERS,
    UPDATE_USER
} from './action_constants';
import {addTokenHeader, refreshToken, ROLES, TOKEN_KEY} from './auth_actions';
import {onError} from "./error_actions";
import {CUR_ORG} from "./session_constants";

// To get the configuration so we can load properties
const Config = require('Config');

export function getUsersByRole(entityType, entityId, role, owningEntity = true) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/user/list/byRole`;
        const request = {
            entityType: entityType,
            entityId: entityId,
            role: role,
            owningEntity: owningEntity
        };
        return axios.post(url, request, addTokenHeader());
    }
}

export function fetchUsersByRole(entityType, entityId, role, owningEntity = true) {
    return function (dispatch) {
        refreshToken(dispatch);
        getUsersByRole(entityType, entityId, role, owningEntity).then(response => {
            dispatch({
                type: FETCH_USERS,
                payload: response
            });
        });
    }
}

export function fetchUsers() {
    return function (dispatch) {
        refreshToken(dispatch);
        const url = `${Config.personServerUrl}/user/list/all`;
        axios.get(url, addTokenHeader()).then((response) => {
            dispatch({
                type: FETCH_USERS,
                payload: response
            });
        });
    }
}

export function fetchUsersNotInRole(entityType, entityId, role, owningEntity = true) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/user/list/notInRole`;
            const request = {
                entityType: entityType,
                entityId: entityId,
                role: role,
                owningEntity: owningEntity
            };
            axios.post(url, request, addTokenHeader()).then(response => {
                dispatch({
                    type: FETCH_USERS,
                    payload: response
                });
            });
        }
    }
}

export async function fetchUsersByEntity(entityType, entityId, owningEntity = false) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/user/list/byEntity`;
        const request = {
            entityType: entityType,
            entityId: entityId,
            owningEntity: owningEntity
        };
        return await axios.post(url, request, addTokenHeader());
    }
}

export async function deleteUserWithinOrg(userId){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)){
        const url = `${Config.personServerUrl}/user/${userId}/delete/withinOrg/${sessionStorage.getItem(CUR_ORG)}`;
        return await axios.delete(url, addTokenHeader());
    }
}

export function deleteUser(userId, callBack, onError) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/user/${userId}/delete`;
            axios.delete(url, addTokenHeader()).then(response => {
                callBack();
                dispatch({
                    type: DELETE_USER,
                    payload: response
                })
            }).catch(onError)
        }
    }
}

export function fetchUsersByOrg(orgId = sessionStorage.getItem(CUR_ORG)) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/user/list/byOrg/${orgId}`;
            axios.get(url, addTokenHeader()).then(response => {
                dispatch({
                    type: FETCH_USERS,
                    payload: response
                });
            });
        }
    }
}

export function fetchUser(id) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const reqUrl = `${Config.personServerUrl}/user/${id}`;
            const response = axios.get(reqUrl, addTokenHeader());

            dispatch({
                type: FETCH_USER_BY_ID,
                payload: response
            });
        }
    }
}

export function fetchUserByPerson(personId) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            //const reqUrl = `${Config.personServerUrl}/user/byPerson/${personId}`;
            const reqUrl = `${Config.personServerUrl}/user/byPerson/${personId}`;
            const response = axios.get(reqUrl, addTokenHeader());

            dispatch({
                type: FETCH_USER_BY_PERSON,
                payload: response
            });
        }
    }
}

export async function updateSysAdmin(userName, callBack, errorCallback) {
    await refreshToken();

    if (sessionStorage.getItem(TOKEN_KEY)) {
        const reqUrl = `${Config.personServerUrl}/user/${userName}/update/role/sysadmin`;
        const response = await axios.post(reqUrl, {}, addTokenHeader()).catch((error) => errorCallback(error));
        callBack();
        return response.data;
    }
}

export async function fetchSysUserName(subjectId, errorCallback) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const reqUrl = `${Config.personServerUrl}/user/userName/get/${subjectId}`
        const response = await axios.get(reqUrl, addTokenHeader()).catch((error) => errorCallback(error));
        return response.data;
    }
}

export async function fetchUserName(subjectId, errorCallback) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/user/userName/get/${subjectId}`;
        const response = await axios.get(reqUrl, addTokenHeader()).catch(errorCallback);
        return response.data;
    }
}

export async function fetchOrgAdminAssignableRole(errorCallback) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const reqUrl = `${Config.personServerUrl}/fieldValues/orgAdminAssignableRoles/list/all`;
        const response = await axios.get(reqUrl, addTokenHeader()).catch((error) => errorCallback(error));
        return response.data;
    }
}

export function createUser(user, callBack, errorCallBack = null) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/user/create/byOrg/${sessionStorage.getItem(CUR_ORG)}`;
            console.log('User is ', user)
            return axios.post(url, user, addTokenHeader()).then((response) => {
                callBack();
                dispatch({
                    type: CREATE_USER,
                    payload: response
                });
            }).catch((error) => {
                if (error && error.response && error.response.status && error.response.data && error.response.data.message) {
                    switch (error.response.status) {
                        case 400:
                        case 404:
                            if (errorCallBack) {
                                errorCallBack(error.response.data.message);
                                return;
                            }
                            break;
                    }
                }
                errorCallBack(error);
            });
        }
    }
}

export function updateUserRole(user, callBack, errorCallBack = null) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/user/update/role/byOrg/${sessionStorage.getItem(CUR_ORG)}`;
            return axios.post(url, user, addTokenHeader()).then((response) => {
                callBack();
                dispatch({
                    type: CREATE_USER,
                    payload: response
                });
            }).catch((error) => {
                if (error && error.response && error.response.status && error.response.data && error.response.data.message) {
                    switch (error.response.status) {
                        case 400:
                        case 404:
                            if (errorCallBack) {
                                errorCallBack(error.response.data.message);
                                return;
                            }
                            break;
                    }
                }
                errorCallBack(error);
            });
        }
    }
}

export function updateUser(user, id, callBack) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            user.userId = id;
            //const url = `${Config.personServerUrl}/user/update`;
            const url = `${Config.personServerUrl}/user/update`;
            const response = axios.post(url, user, addTokenHeader()).then(() => callBack()); // when promise returns then we call the callback

            return {
                type: UPDATE_USER,
                payload: response
            }
        }
    }
}

export function confirmPasswordChange(userObject, hashCode) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            const url = `${Config.taskServerUrl}/user/passwordReset/${hashCode}/confirmChanges`;
            axios.post(url, userObject, addTokenHeader()).then((response) => {
                resolve(response.data);

                dispatch({
                    type: UPDATE_USER,
                    payload: response
                })
            }).catch(reject);
        })
    }
}

export function updateUserPassword(userObject, callBack) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/user/updatePassword`;
            axios.post(url, userObject, addTokenHeader()).then((response) => {
                callBack();

                dispatch({
                    type: UPDATE_USER,
                    payload: response
                })
            });
        }
    }
}

export function updateUserRoleForOrg(userId, orgId, roleType, callback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const shouldRemove = roleType === "NONE";
            let promise;
            if (shouldRemove) {
                const url = `${Config.personServerUrl}/user/${userId}/removeRoleForOrg/${orgId}`;
                promise = axios.delete(url, addTokenHeader());
            } else {
                const request = {
                    userId, orgId, roleType
                };
                const url = `${Config.personServerUrl}/user/updateRoleForOrg`;
                promise = axios.post(url, request, addTokenHeader());
            }
            promise.then((response) => {
                callback();
                dispatch({
                    type: UPDATE_USER,
                    payload: response
                })
            })
        }
    }
}

export function addServiceCenterToOrg(owningOrgId, orgId, userId, callback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/user/org/serviceCenter/add`;
            const request = {
                owningOrgId, orgId, userId
            };
            axios.post(url, request, addTokenHeader()).then(response => {
                callback();
                dispatch({
                    type: FETCH_USER_BY_ID,
                    payload: response.data
                });
            });
        }
    }
}

export function removeServiceCenterToOrg(owningOrgId, orgId, userId, callback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/user/org/serviceCenter/remove`;
            const request = {
                owningOrgId, orgId, userId
            };
            axios.post(url, request, addTokenHeader()).then(response => {
                callback();
                dispatch({
                    type: FETCH_USER_BY_ID,
                    payload: response.data
                });
            });
        }
    }
}