/**
 * Created by shivaraman on 5/10/17.
 */
import axios from 'axios';

import {
    CREATE_PERSON,
    FETCH_PERSON_BY_ID,
    FETCH_PERSONS,
    FETCH_SUBJECT_REPORTABLES,
    FETCHED_PERSONS_FOR_REMOVAL,
    REPORT_GENERATED,
    SEARCH_PERSONS,
    SELF_REPORTING_AUTH_DONE,
    SELF_REPORTING_DONE,
    SUBJECT_COMPLIANCE_REPORT,
    SUBJECT_REVIEW_INITIATED,
    UPDATE_PERSON
} from './action_constants';
import {
    addTokenHeader,
    addWithCredentials,
    refreshToken,
    REPORTING_PASS_CODE,
    SUBJECT_ID,
    TOKEN_KEY,
    VERIFIED_EMAIL
} from './auth_actions';
import {getDataFromDocs, getDataFromDocsAsync, getDocDetail} from "./doc_actions";
import {CUR_ORG} from "./session_constants";
import {CommonUtils} from "./common_utils";

// To get the configuration so we can load properties
const Config = require('Config');

/**
 * Extracts underlying Subject from SubjectUI and combines the two.
 * @param subject SubjectUI object from axios
 * @returns {*} Combined Subject and SubjectUI
 */
export function getSubjectObj(subject) {
    if (subject) {
        return subject.personObject ? {...subject.personObject, ...subject} : subject;
    }
    return null;
}

const extractStaticValue = (fieldDef) => {
    let delimiter = '--';
    let firstIndex = fieldDef.indexOf(delimiter, 0);
    let staticVal = '';
    let text = fieldDef;
    if (firstIndex > -1) {
        let secondIndex = fieldDef.indexOf(delimiter, firstIndex + delimiter.length);
        if (secondIndex > -1) {
            staticVal = fieldDef.substring(firstIndex + delimiter.length, secondIndex);
            text = fieldDef.substring(0, firstIndex) + fieldDef.substring(secondIndex + delimiter.length)
        }
    }
    return {staticVal, text}
}

function extractCommaSeparatedData(text, subject, retValue, delimiter) {
    let indFieldArr = text.split(delimiter);
    for (let indField of indFieldArr) {
        if (subject[indField]) {
            retValue = retValue + subject[indField] + delimiter;
        }
    }
    retValue = retValue.substr(0, retValue.length - 1);
    return retValue;
}

const formatDataForCsv = (fieldDef, subject) => {
    let {staticVal, text} = extractStaticValue(fieldDef);

    let retValue = '';
    if (text.includes(' ')) {
        retValue = extractCommaSeparatedData(text, subject, retValue, ' ');
    } else if (text.includes(',')) {
        retValue = extractCommaSeparatedData(text, subject, retValue, ', ');
    } else if (text.includes('|')) {
        let indFieldArr = text.split('|');
        for (let indField of indFieldArr) {
            if (subject[indField]) {
                retValue = subject[indField];
            }
        }
    } else if (text.includes('.')) {
        let indFieldArr = text.split('.');
        retValue = subject;
        for (let indField of indFieldArr) {
            if (retValue[indField]) {
                retValue = retValue[indField];
            } else {
                retValue = '';
            }
        }
        // console.log(`Field def is ${text} and retValue is ${retValue}`);
    } else if (subject[text]) {
        retValue = subject[text];
    }
    if (staticVal) {
        if (retValue) {
            retValue = ' ' + retValue;
        }
        retValue = staticVal + retValue;
    }
    // console.log('Ret value is ', retValue)
    return retValue;
}

const addComplianceHeader = (requiredDocTypes, headerString) => {
    for (let docType of requiredDocTypes) {
        headerString = headerString + docType + ",";
    }
    return headerString;
}

const addComplianceRowData = (subject, eachLineString) => {
    for (let docType of subject.docTypesRequired) {
        let complianceCompletedDate = subject.docTypes2DateCompletedMap[docType];
        if (complianceCompletedDate) {
            eachLineString = eachLineString + complianceCompletedDate + ",";
        } else {
            eachLineString = eachLineString + ",";
        }
    }
    return eachLineString;
}

export function generateReport(thisReportMap, reportType, subjects) {
    console.log('Report map is ', thisReportMap)
    console.log('Subs are ', subjects)
    return function (dispatch) {
        if (sessionStorage.getItem(TOKEN_KEY) && thisReportMap) {
            let subjectReportLines = "";
            let headerString = "";
            let objectEntriesArr = Object.entries(thisReportMap);
            if (objectEntriesArr && objectEntriesArr.length > 0) {
                for (let [eachEntry, eachValue] of objectEntriesArr) {
                    headerString = headerString + eachEntry + ",";
                }
                if (reportType === SUBJECT_COMPLIANCE_REPORT && subjects.length > 0) {
                    headerString = addComplianceHeader(subjects[0].docTypesRequired, headerString);
                }
                subjectReportLines = subjectReportLines + headerString.substr(0, headerString.length - 1) + "\n";
                for (let eachSubject of subjects) {
                    let eachLineString = "";
                    for (let [eachEntry, eachValue] of objectEntriesArr) {
                        let formattedValue = formatDataForCsv(eachValue, eachSubject);
                        if (formattedValue) {
                            eachLineString = eachLineString + `"${formattedValue}"` + ",";
                        } else {
                            eachLineString = eachLineString + ",";
                        }
                    }
                    if (reportType === SUBJECT_COMPLIANCE_REPORT) {
                        eachLineString = addComplianceRowData(eachSubject, eachLineString);
                    }
                    subjectReportLines = subjectReportLines + eachLineString.substr(0, eachLineString.length - 1) + "\n";
                }

                // Create hidden anchor tag
                let a = document.createElement("a");
                let byteNumbers = new Array(subjectReportLines.length);
                for (let i = 0; i < subjectReportLines.length; i++) {
                    byteNumbers[i] = subjectReportLines.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                // Create a URL to download blob data.
                const blob = new Blob([byteArray], {type: null}),
                    url = window.URL.createObjectURL(blob);
                // Add tag to body
                document.body.appendChild(a);
                // Hide the anchor tag
                a.style = "display: none";
                // Set URL and FileName
                a.href = url;
                a.download = reportType.replace("Map", "") + ".csv";
                // Trigger the anchor tag for browser download
                a.click();
                // Remove the blob url to conserve memory
                window.URL.revokeObjectURL(url);
                // Delete the anchor tag.
                document.body.removeChild(a);
            }
        }
        dispatch({
            type: REPORT_GENERATED
        })
    }
}

export function fetchPersons(lastName = null, org = null, smo = null, firstName = null, ssn = null) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            let initUrl = `${Config.personServerUrl}`;

            if (!org || org === 'null') {
                initUrl = `${Config.personServerUrl}/personLikeAndNoOrg/${lastName}`;
            } else if (org === '.*') {
                initUrl = `${Config.personServerUrl}/personLike/${lastName}`;
            } else {
                if (!smo || smo === 'null') {
                    initUrl = `${Config.personServerUrl}/personLikeAndOrgNoSmo/${lastName}/${org}`;
                } else if (smo === '.*') {
                    initUrl = `${Config.personServerUrl}/personLikeAndOrg/${lastName}/${org}`;
                } else {
                    initUrl = `${Config.personServerUrl}/personLikeAndOrgAndSmo/${lastName}/${org}/${smo}`;
                }
            }

            const url = initUrl;
            // this returns a promise and axios middleware will wait till the response is got
            axios.get(url, addTokenHeader()).then(response => {
                dispatch({
                    type: FETCH_PERSONS,
                    payload: response
                });
                // window.location.href = "/";
            });
        }
    }
}

export function fetchDuplicateSubjectsBySsn() {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/person/list/duplicatesBySsn`;

            axios.get(url, addTokenHeader()).then((response) => {
                dispatch({
                    type: FETCH_PERSONS,
                    payload: response
                });
            });
        }
    }
}

export function mergeDuplicateSubject(ssn, subjectDetails, callBack) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/subject/mergeBySsn/${ssn}`;

            subjectDetails.issues = [];
            subjectDetails.orgs = [];
            subjectDetails.facilities = [];
            subjectDetails.foreignContacts = [];
            subjectDetails.foreignRelatives = [];
            axios.post(url, subjectDetails, addTokenHeader()).then((response) => {
                callBack();
            });
        }
    }
}

export async function fetchPersonAsync(id) {
    const reqUrl = `${Config.personServerUrl}/${sessionStorage.getItem(CUR_ORG)}/person/${id}`;
    let response = await axios.get(reqUrl, addTokenHeader());
    return response.data;
}

export function fetchPerson(id) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const reqUrl = `${Config.personServerUrl}/${sessionStorage.getItem(CUR_ORG)}/person/${id}`;
            axios.get(reqUrl, addTokenHeader()).then(response => {
                dispatch({
                    type: FETCH_PERSON_BY_ID,
                    payload: response
                });
            });
        }
    }
}

export function fetchPersonWithoutHidingDetails(id) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const reqUrl = `${Config.personServerUrl}/${sessionStorage.getItem(CUR_ORG)}/person/${id}/hideNothing`;
            axios.get(reqUrl, addTokenHeader()).then(response => {
                dispatch({
                    type: FETCH_PERSON_BY_ID,
                    payload: response
                });
            });
        }
    }
}

export function fetchSubjectByBusinessKey(request, errorCallback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const reqUrl = `${Config.personServerUrl}/person/byBusinessKey`;
            axios.post(reqUrl, request, addTokenHeader()).then(response => {
                dispatch({
                    type: FETCH_PERSON_BY_ID,
                    payload: response
                });
            }).catch(errorCallback);
        }
    }
}

export async function fetchSubjectBySocial(ssn) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const reqUrl = `${Config.personServerUrl}/person/bySsn`;
        const request = {
            ssn
        };

        console.log('ssn in request is ', ssn)
        const response = await axios.post(reqUrl, request, addTokenHeader());
        return response.data;
    }
}

export function fetchSubjectBySsn(ssn, errorCallback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const reqUrl = `${Config.personServerUrl}/person/bySsn`;
            const request = {
                ssn
            };

            axios.post(reqUrl, request, addTokenHeader()).then(response => {
                dispatch({
                    type: FETCH_PERSON_BY_ID,
                    payload: response
                });
            }).catch(errorCallback);
        }
    }
}

export async function fetchSubjectForUserCreation(errorCallback) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const reqUrl = `${Config.personServerUrl}/personWithUser/list/byOrg/${sessionStorage.getItem(CUR_ORG)}`;

        const response = await axios.get(reqUrl, addTokenHeader()).catch(errorCallback);
        return response.data;
    }
}

export function fetchSubjectsNotInBulkUpload(uploadedSubjects, errorCallback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/subjects/getNotInBulkUpload`;

            axios.post(reqUrl, uploadedSubjects, addTokenHeader()).then(response => {
                dispatch({
                    type: FETCHED_PERSONS_FOR_REMOVAL,
                    payload: response
                });
            }).catch(errorCallback);
        }
    }
}

export function createPerson(person, callBack, errorCallback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/person/create`;
            if (person.orgId) {
                person.orgs = [{
                    orgId: person.orgId,
                    employeeType: person.employeeType,
                    title: person.jobTitle,
                    ndaSignedDate: person.ndaSignedDate
                }];
            }
            if (person.contracts) {
                person.contracts = person.contracts.map(contractId => {
                    return {
                        contractId: contractId,
                        startDate: new Date()
                    }
                });
            }
            axios.post(url, person, addTokenHeader()).then((response) => {
                callBack();

                dispatch({
                    type: CREATE_PERSON,
                    payload: response
                });
            }).catch(errorCallback);
        }
    }
}

export function updateSubjectGeneralDetails(subjectId, subject, callBack, errorCallback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/${sessionStorage.getItem(CUR_ORG)}/subject/${subjectId}/update/general`;
            subject.orgs = [];

            axios.post(url, subject, addTokenHeader()).then((response) => {
                callBack();

                return {
                    type: UPDATE_PERSON,
                    payload: response
                }
            }).catch(errorCallback);
        }
    }
}

export function updateSubjectEqipInvestigation(eQipInfo, subjectId, callBack) {
    return function (dispatch) {
        refreshToken();
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/${sessionStorage.getItem(CUR_ORG)}/subject/${subjectId}/update/eqip`;

            axios.post(url, eQipInfo, addTokenHeader()).then((response) => {
                callBack();

                return {
                    type: UPDATE_PERSON,
                    payload: response
                }
            });
        }
    }
}

export function updateSubjectClearanceDetails(subjectId, subject, callBack) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/${sessionStorage.getItem(CUR_ORG)}/subject/${subjectId}/update/clearance`;
            subject.orgs = [];

            axios.post(url, subject, addTokenHeader()).then((response) => {
                callBack();

                return {
                    type: UPDATE_PERSON,
                    payload: response
                }
            });
        }
    }
}

export function addOrgToSubject(subjectId, orgRelationship, callBack) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY) && orgRelationship.orgId) {
            const url = `${Config.personServerUrl}/subject/${subjectId}/org/add`;

            axios.post(url, orgRelationship, addTokenHeader()).then((response) => {
                callBack();

                return {
                    type: UPDATE_PERSON,
                    payload: response
                }
            });
        }
    }
}

export function updateOrgSubject(subjectId, orgRelationship, callBack) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY) && orgRelationship.orgId) {
            const url = `${Config.personServerUrl}/subject/${subjectId}/org/add`;

            axios.post(url, orgRelationship, addTokenHeader()).then((response) => {
                callBack();

                return {
                    type: UPDATE_PERSON,
                    payload: response
                }
            });
        }
    }
}

export function fetchActivePersonsByOrg(orgId) {
    return function (dispatch) {
        refreshToken(dispatch);
        getActiveOrgPersons((response) => {
            response.data = response.data.map((subject) => {
                subject.active = !!subject.orgs.find((rel) => rel.orgId === orgId && rel.active);
                return subject;
            });

            dispatch({
                type: FETCH_PERSONS,
                payload: response
            });
        });
    }
}

export async function fetchActivePersonsByActiveOrgAsync() {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/person/list/byOrgActiveFor/${sessionStorage.getItem(CUR_ORG)}`;
        const response = await axios.get(url, addTokenHeader());
        return response.data.sort((a, b) => a.personFirstName.localeCompare(b.personFirstName));
    } else {
        return [];
    }
}

export function getActiveOrgPersons(callback) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/person/list/byOrgActiveFor/${sessionStorage.getItem(CUR_ORG)}`;
        axios.get(url, addTokenHeader()).then(callback);
    }
}

export function getAllSubjectsForAnOrg() {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/person/list/byOrg/all/${sessionStorage.getItem(CUR_ORG)}`;
            axios.get(url, addTokenHeader()).then((response) => {
                dispatch({
                    type: FETCH_PERSONS,
                    payload: response
                });
            });
        }
    }
}

export async function getSubjectsForOrgWithFilter(personFirstNameFilter = "", personFirstNameSort = 0,
                                                  personLastNameFilter = "", personLastNameSort = 0,
                                                  socialSecurityNumberFilter = "", socialSecurityNumberSort = 0,
                                                  stateOfBirthFilter = "", stateOfBirthSort = 0,
                                                  countryOfBirthFilter = "", countryOfBirthSort = 0,
                                                  affiliationsFilter = "", affiliationsSort = 0,
                                                  contractAffiliationFilter = "", contractAffiliationSort = 0,
                                                  activeStatusFilter = "Active", activeStatusSort = 0,
                                                  page = -1, sizePerPage = -1) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/person/list/byOrg/filtered/${sessionStorage.getItem(CUR_ORG)}`;

        const queryObj = {
            personFirstNameFilter, personFirstNameSort,
            personLastNameFilter, personLastNameSort,
            socialSecurityNumberFilter, socialSecurityNumberSort,
            stateOfBirthFilter, stateOfBirthSort,
            countryOfBirthFilter, countryOfBirthSort,
            affiliationsFilter, affiliationsSort,
            contractAffiliationFilter, contractAffiliationSort,
            activeStatusFilter, activeStatusSort,
            page, sizePerPage
        };

        console.log('Query Obj is ', queryObj)
        const response = await axios.post(url, queryObj, addTokenHeader());
        console.log('Response is ', response);
        return response.data;
    }
    return [];
}

export async function fetchActiveOrgPersonsWithInvalidEmail() {
    const url = `${Config.personServerUrl}/person/list/byOrgActiveFor/${sessionStorage.getItem(CUR_ORG)}/invalidEmails`;
    const response = await axios.get(url, addTokenHeader());
    return response.data;
}

export async function fetchActiveOrgPersonsEligibleForScreening() {
    const url = `${Config.personServerUrl}/person/list/byOrgActiveFor/${sessionStorage.getItem(CUR_ORG)}/eligibleForScreening`;
    const response = await axios.get(url, addTokenHeader());
    return response.data;
}

export function getAllPersons(callback) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/personLike/.*`;
        axios.get(url, addTokenHeader()).then(callback);
    }
}

// Find Subjects By Contract
export function fetchSubjectsByContractId(contractId) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/person/list/byContractId/${contractId}`;
            axios.get(url, addTokenHeader()).then((response) => {
                dispatch({
                    type: FETCH_PERSONS,
                    payload: response
                });
            });
        }
    }
}

export function fetchSubjectsByContractIdAndActiveFor(contractId) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/person/list/byContractIdAndActiveFor/${contractId}`;
            axios.get(url, addTokenHeader()).then((response) => {
                dispatch({
                    type: FETCH_PERSONS,
                    payload: response
                });
            });
        }
    }
}

export async function fetchActiveSubjectsByContractIdAsync(contractId) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/person/list/byContractIdAndActiveFor/${contractId}`;
        const response = await axios.get(url, addTokenHeader());
        return response.data;
    } else {
        return [];
    }
}

export async function getOrgSubjectsWithLessFields() {
    const url = `${Config.personServerUrl}/person/list/byOrgActiveFor/${sessionStorage.getItem(CUR_ORG)}/withCurtailedFields`;
    return axios.get(url, addTokenHeader());
}

export function searchSubjectsNotActivelyPartOfContract(contractId, searchTerm, callback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/subject/list/searchNotActivelyPartOfContract/${contractId}/${searchTerm}`;
            axios.get(url, addTokenHeader()).then((response) => {
                callback();
                dispatch({
                    type: SEARCH_PERSONS,
                    payload: response
                });
            });
        }
    }
}

export function removeContractFromSubject(subjectId, contractSubjectRel, callBack) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY) && contractSubjectRel.contractId) {
            const url = `${Config.personServerUrl}/subject/${subjectId}/contract/remove`;

            axios.post(url, contractSubjectRel, addTokenHeader()).then((response) => {
                callBack();

                return {
                    type: UPDATE_PERSON,
                    payload: response
                }
            });
        }
    }
}

export function addContractToSubjects(contractId, subjects, callback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/person/contract/${contractId}/addSubjects`;
            axios.post(url, subjects, addTokenHeader()).then((response) => {
                callback();
                dispatch({
                    type: FETCH_PERSONS,
                    payload: response
                });
            })
        }
    }
}

export function fetchSubjectsByFacilityId(facilityId) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/person/list/byFacilityId/${facilityId}`;
            axios.get(url, addTokenHeader()).then((response) => {
                dispatch({
                    type: FETCH_PERSONS,
                    payload: response
                });
            });
        }
    }
}

export async function fetchSubjectsByFacilityIdAsync(facilityId) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/person/list/byFacilityId/${facilityId}`;
        const response = await axios.get(url, addTokenHeader());
        return response.data;
    } else {
        return [];
    }
}

export function searchSubjectsNotPartOfFacility(facilityId, searchTerm, callback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/subject/list/searchNotPartOfFacility/${facilityId}/${searchTerm}`;
            axios.get(url, addTokenHeader()).then((response) => {
                callback();
                dispatch({
                    type: SEARCH_PERSONS,
                    payload: response
                });
            });
        }
    }
}

export function addFacilityToSubjects(facilityId, subjects, callback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/person/facility/${facilityId}/addSubjects`;
            axios.post(url, subjects, addTokenHeader()).then((response) => {
                callback();
                dispatch({
                    type: FETCH_PERSONS,
                    payload: response
                });
            })
        }
    }
}

export async function getForeignTravel(foreignTravelId) {
    const url = `${Config.taskServerUrl}/subject/selfReporting/foreignTravel/byId/${foreignTravelId}`;
    return await axios.get(url, addWithCredentials());
}

export async function cancelForeignTravel(foreignTravelId) {
    const url = `${Config.taskServerUrl}/subject/selfReporting/foreignTravel/${foreignTravelId}/cancel`;
    await axios.post(url, getEmailAuthCreds(), addWithCredentials());
}

export function getEmailAuthCreds() {
    let reqObj = {
        passCode: sessionStorage.getItem(REPORTING_PASS_CODE),
        emailAddress: sessionStorage.getItem(VERIFIED_EMAIL)
    }
    return reqObj;
}

export async function getSubjectUsingEmailForVisit(email) {
    let reqObj = getEmailAuthCreds();
    console.log('Request is ', reqObj)
    const url = `${Config.taskServerUrl}/subject/selfReporting/visit/validateEmail/${email}`;
    return axios.post(url, reqObj, addWithCredentials());
}

export async function getSubjectUsingEmailForVisitFix(email, visitId) {
    console.log(`Visit is ${visitId} and email is ${email}`)
    const url = `${Config.taskServerUrl}/subject/selfReporting/visit/${visitId}/validateEmail/${email}`;
    return axios.get(url, addWithCredentials());
}

export async function reportForSubjectBySO(reportableActivity) {
    // If there are docs then add them
    const url = `${Config.personServerUrl}/subject/${reportableActivity.subjectId}/selfReporting/bySO`;
    if (reportableActivity.supportingDocs) {
        const docDataResp = await getDataFromDocsAsync(reportableActivity.supportingDocs);
        reportableActivity.supportingDocs = CommonUtils.createDocForUploadingForFormik(docDataResp);
        const response = await axios.post(url, reportableActivity, addTokenHeader());
        console.log('Data saved successfully');
        return response;
    } else {
        const response = await axios.post(url, reportableActivity, addTokenHeader());
        return response;
    }
}

export async function reportForeignTravel(foreignTravel, callback, errorCallBack) {
    // If there are contacts with docs then convert them
    if (foreignTravel.foreignContacts) {
        for (let foreignContact of foreignTravel.foreignContacts) {
            if (foreignContact.files && foreignContact.files.length > 0) {
                const docs = await getDataFromDocsAsync(foreignContact.files);
                foreignContact['supportingDocs'] = CommonUtils.createDocForUploadingForFormik(docs);
            }
        }
    }
    return function (dispatch) {
        foreignTravel.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
        foreignTravel.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);
        const url = `${Config.taskServerUrl}/subject/selfReporting/reportForeignTravel`;
        console.log('Submitting foreign travel ', url, foreignTravel);
        axios.post(url, foreignTravel, addWithCredentials()).then((response) => {
            let subjectId = response.data;
            console.log(`Foreign travel report submission for ${subjectId} was successful`);
            callback();
            dispatch({
                type: SELF_REPORTING_DONE,
                payload: response
            });
        }).catch(errorCallBack);
    }
}

export async function reportForeignContact(foreignContacts, callback, errorCallback) {
    for (let foreignContact of foreignContacts.contacts) {
        if (foreignContact.files && foreignContact.files.length > 0) {
            const docs = await getDataFromDocsAsync(foreignContact.files);
            foreignContact['supportingDocs'] = CommonUtils.createDocForUploadingForFormik(docs);
        }
    }
    return function (dispatch) {
        foreignContacts.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
        foreignContacts.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);

        const url = `${Config.taskServerUrl}/subject/selfReporting/reportForeignContact`;
        console.log('Submitting foreign contacts ', url, foreignContacts);
        axios.post(url, foreignContacts, addWithCredentials()).then((response) => {
            callback();

            dispatch({
                type: SELF_REPORTING_DONE,
                payload: response
            })
        }).catch(errorCallback);
    }
}

export function reportForeignChildAdoption(foreignAdoption, callback, errorCallback) {
    return function (dispatch) {
        foreignAdoption.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
        foreignAdoption.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);
        const url = `${Config.taskServerUrl}/subject/selfReporting/reportForeignChildAdoption`;
        axios.post(url, foreignAdoption, addWithCredentials()).then((response) => {
            callback();

            dispatch({
                type: SELF_REPORTING_DONE,
                payload: response
            })
        }).catch(errorCallback);
    }
}

export function reportMediaContact(mediaContact) {
    return (dispatch) => {
        // Set reportable information
        mediaContact.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
        mediaContact.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);

        // Return the promise to calling function
        return new Promise((resolve, reject) => {
            const url = `${Config.taskServerUrl}/subject/selfReporting/reportMediaContact`;
            axios.post(url, mediaContact, addWithCredentials()).then((response) => {
                resolve(response);

                dispatch({
                    type: SELF_REPORTING_DONE,
                    payload: response
                });
            }).catch(reject);
        });
    };
}

export function reportFinancialIssueAndAnomaly(financialIssueAndAnomaly, callback, errorCallback) {
    return function (dispatch) {
        financialIssueAndAnomaly.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
        financialIssueAndAnomaly.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);
        const url = `${Config.taskServerUrl}/subject/selfReporting/reportFinancialIssueAndAnomaly`;
        axios.post(url, financialIssueAndAnomaly, addWithCredentials()).then((response) => {
            callback();

            dispatch({
                type: SELF_REPORTING_DONE,
                payload: response
            })
        }).catch(errorCallback);
    }
}

export function reportAlcoholDrugUse(alcoholdrugUse, callback, errorCallback) {
    return function (dispatch) {
        alcoholdrugUse.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
        alcoholdrugUse.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);
        const url = `${Config.taskServerUrl}/subject/selfReporting/reportAlcoholDrugTreatment`;
        axios.post(url, alcoholdrugUse, addWithCredentials()).then((response) => {
            callback();

            dispatch({
                type: SELF_REPORTING_DONE,
                payload: response
            })
        }).catch(errorCallback);
    }
}

export function reportOnOthers(reportOnOthersObj, callback, errorCallback) {
    return function (dispatch) {
        reportOnOthersObj.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
        reportOnOthersObj.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);
        const url = `${Config.taskServerUrl}/subject/selfReporting/reportOnOthers`;
        axios.post(url, reportOnOthersObj, addWithCredentials()).then((response) => {
            callback();

            dispatch({
                type: SELF_REPORTING_DONE,
                payload: response
            })
        }).catch(errorCallback);
    }
}

export function reportBlackmail(reportable, callback, errorCallback) {
    return function (dispatch) {
        reportable.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
        reportable.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);
        const url = `${Config.taskServerUrl}/subject/selfReporting/reportBlackmail`;

        axios.post(url, reportable, addWithCredentials()).then((response) => {
            callback();

            dispatch({
                type: SELF_REPORTING_DONE,
                payload: response
            })
        }).catch(errorCallback);
    }
}


export function reportForeignIntelligenceContact(foreignIntelligenceContact, callback, errorCallback) {
    return function (dispatch) {
        foreignIntelligenceContact.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
        foreignIntelligenceContact.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);
        const url = `${Config.taskServerUrl}/subject/selfReporting/reportForeignIntelligenceContact`;
        axios.post(url, foreignIntelligenceContact, addWithCredentials()).then((response) => {
            callback();

            dispatch({
                type: SELF_REPORTING_DONE,
                payload: response
            })
        }).catch(errorCallback);
    }
}

export function reportSubjectInitiateReview(initiateSubjectReview) {
    return function (dispatch) {
        initiateSubjectReview.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
        initiateSubjectReview.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);
        return new Promise((resolve, reject) => {
            const url = `${Config.taskServerUrl}/subject/selfReporting/reportSubjectInitiateReview`;

            axios.post(url, initiateSubjectReview, addWithCredentials()).then((response) => {
                resolve(response.data);

                dispatch({
                    type: SUBJECT_REVIEW_INITIATED,
                    payload: response
                })
            }).catch(reject);
        });
    }
}

export function reportForeignVoting(foreignVoting, callback, errorCallback) {
    return function (dispatch) {
        foreignVoting.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
        foreignVoting.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);
        const url = `${Config.taskServerUrl}/subject/selfReporting/reportForeignVoting`;
        console.log(foreignVoting)
        axios.post(url, foreignVoting, addWithCredentials()).then((response) => {
            callback();

            dispatch({
                type: SELF_REPORTING_DONE,
                payload: response
            })
        }).catch(errorCallback);
    }
}

export function reportAddressChange(address, callback, errorCallback) {
    return function (dispatch) {
        address.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
        address.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);
        const url = `${Config.taskServerUrl}/subject/selfReporting/reportAddressChange`;
        axios.post(url, address, addWithCredentials()).then((response) => {
            callback();

            dispatch({
                type: SELF_REPORTING_DONE,
                payload: response
            })
        }).catch(errorCallback);
    }
}

export function reportMaritalStatusChange(spouse, callback, errorCallback) {
    return function (dispatch) {
        spouse.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
        spouse.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);
        const url = `${Config.taskServerUrl}/subject/selfReporting/reportMaritalStatusChange`;
        if (spouse.supportingDocs) {
            getDocDetail(spouse.supportingDocs[0].previewUrl).then(docDataResp => {
                spouse.supportingDocs[0].revisions[0].data = new Buffer(docDataResp.data, 'binary').toString('base64');

                axios.post(url, spouse, addWithCredentials()).then((response) => {
                    window.URL.revokeObjectURL(spouse.supportingDocs[0].previewUrl);
                    callback();

                    dispatch({
                        type: SELF_REPORTING_DONE,
                        payload: response
                    })
                }).catch(errorCallback);
            })
        } else {
            axios.post(url, spouse, addWithCredentials()).then((response) => {
                callback();
                dispatch({
                    type: SELF_REPORTING_DONE,
                    payload: response
                })
            }).catch(errorCallback);
        }
    }
}

export function reportCohabitantChange(cohabitant) {
    return function (dispatch) {
        cohabitant.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
        cohabitant.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);
        return new Promise((resolve, reject) => {
            const url = `${Config.taskServerUrl}/subject/selfReporting/reportCohabitantChange`;

            axios.post(url, cohabitant, addWithCredentials()).then((response) => {
                resolve(response.data);

                dispatch({
                    type: SELF_REPORTING_DONE,
                    payload: response
                })
            }).catch(reject);
        });
    }
}

export function reportArrestChange(arrest) {
    return function (dispatch) {
        arrest.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
        arrest.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);
        return new Promise((resolve, reject) => {
            const url = `${Config.taskServerUrl}/subject/selfReporting/reportArrestChange`;

            axios.post(url, arrest, addWithCredentials()).then((response) => {
                resolve(response.data);

                dispatch({
                    type: SELF_REPORTING_DONE,
                    payload: response
                })
            }).catch(reject);
        });
    }
}

export function reportForeignBankAccountChange(reportable) {
    return function (dispatch) {
        reportable.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
        reportable.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);
        return new Promise((resolve, reject) => {
            const url = `${Config.taskServerUrl}/subject/selfReporting/reportForeignBankAccount`;

            axios.post(url, reportable, addWithCredentials()).then((response) => {
                resolve(response.data);

                dispatch({
                    type: SELF_REPORTING_DONE,
                    payload: response
                })
            }).catch(reject);
        });
    }
}

export function reportSubjectChangeWithSupportingDoc(reportable, url, callback, errorCallback) {
    return function (dispatch) {
        reportable.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
        reportable.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);

        // Resolve Files
        getDataFromDocs(reportable.supportingDocs, (docDataResp) => {
            // Convert docs to uploadable format
            reportable.supportingDocs = CommonUtils.createDocForUploadingForFormik(docDataResp);

            // Continue Reportable Upload
            axios.post(`${Config.taskServerUrl}/${url}`, reportable, addWithCredentials()).then((response) => {
                callback();

                dispatch({
                    type: SELF_REPORTING_DONE,
                    payload: response
                })
            }).catch(errorCallback);
        });
    }
}

export function reportForeignBusinessInvolvement(reportable) {
    return function (dispatch) {
        reportable.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
        reportable.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);
        return new Promise((resolve, reject) => {
            const url = `${Config.taskServerUrl}/subject/selfReporting/reportForeignBusinessInvolvement`;

            axios.post(url, reportable, addWithCredentials()).then((response) => {
                resolve(response.data);

                dispatch({
                    type: SELF_REPORTING_DONE,
                    payload: response
                })
            }).catch(reject);
        });
    }
}

export function reportForeignProperty(reportable) {
    return function (dispatch) {
        reportable.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
        reportable.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);
        return new Promise((resolve, reject) => {
            const url = `${Config.taskServerUrl}/subject/selfReporting/reportForeignProperty`;

            axios.post(url, reportable, addWithCredentials()).then((response) => {
                resolve(response.data);

                dispatch({
                    type: SELF_REPORTING_DONE,
                    payload: response
                })
            }).catch(reject);
        });
    }
}

export function checkEmail(emailCheck) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            const url = `${Config.personServerUrl}/person/emailCheck`;

            axios.post(url, emailCheck, addWithCredentials()).then((response) => {
                resolve(response.data);
                console.log('Subject got is ', response.data)
                dispatch({
                    type: SELF_REPORTING_AUTH_DONE,
                    payload: response.data.subject
                })
            }).catch(reject);

        });

    }
}

export async function getSubjectForSelfReporting() {
    const url = `${Config.personServerUrl}/person/emailCheck/passCodeCheck`;

    const emailReq = {
        emailAddress: sessionStorage.getItem(VERIFIED_EMAIL),
        passCode: sessionStorage.getItem(REPORTING_PASS_CODE)
    }

    return (await axios.post(url, emailReq, addWithCredentials())).data;
}

export function reportingSecondFactor(emailCheck) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            const url = `${Config.personServerUrl}/person/emailCheck/passCodeCheck`;

            axios.post(url, emailCheck, addWithCredentials()).then((response) => {
                console.log('Subject response after second factor is ', response);
                resolve(response.data);
                dispatch({
                    type: SELF_REPORTING_AUTH_DONE,
                    payload: response.data
                })
            }).catch(reject);

        });

    }
}

export function removeFacilityFromSubjects(facilityId, subjects, callback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/person/facility/${facilityId}/removeSubjects`;
            axios.post(url, subjects, addTokenHeader()).then((response) => {
                callback();
                dispatch({
                    type: FETCH_PERSONS,
                    payload: response
                });
            });
        }
    }
}

export function removeOrgFromSubject(subjectId, orgRelationship, callBack) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY) && orgRelationship.orgId) {
            const url = `${Config.personServerUrl}/subject/${subjectId}/org/remove`;

            axios.post(url, orgRelationship, addTokenHeader()).then((response) => {
                callBack();

                return {
                    type: UPDATE_PERSON,
                    payload: response
                }
            });
        }
    }
}

export function updateFacilityRelForSubjects(facilityId, subjects, relType, callback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/person/facility/${facilityId}/updateSubjectsRelationships/${relType}`;
            axios.post(url, subjects, addTokenHeader()).then((response) => {
                callback();
                dispatch({
                    type: FETCH_PERSONS,
                    payload: response
                });
            });
        }
    }
}

export function updateSubjectContractBriefing(subjectId, briefingObj, callback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/person/${subjectId}/contract/updateBriefing`;
            getDataFromDocs(briefingObj.documents, (docResp) => {
                briefingObj.briefDocs = docResp.map(doc => {
                    return {
                        document: {
                            name: doc.name,
                            type: "OTHER"
                        },
                        revisions: [
                            {
                                fileName: doc.name,
                                fileType: doc.type,
                                data: doc.data,
                                uploader: sessionStorage.getItem(SUBJECT_ID)
                            }
                        ]
                    };
                });
                axios.post(url, briefingObj, addTokenHeader()).then(response => {
                    callback();
                    dispatch({
                        type: FETCH_PERSON_BY_ID,
                        payload: response
                    });
                })
            });

        }
    }
}

export function getSubjectDob(subjectId, callback) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/person/${subjectId}/sensitiveData/dob`;
        axios.get(url, addTokenHeader()).then(callback);
    }
}

export function getSubjectSsn(subjectId, callback) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/person/${subjectId}/sensitiveData/ssn`;
        axios.get(url, addTokenHeader()).then(callback);
    }
}

export function getSubjectReportables(hashCode) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            const url = `${Config.personServerUrl}/person/selfReporting/reviewInfo/${hashCode}`;
            axios.get(url, addTokenHeader()).then((response) => {
                dispatch({
                    type: FETCH_SUBJECT_REPORTABLES,
                    payload: response
                });
                resolve(response.data);
            }).catch(reject);
        });
    }
}

export async function invalidateSubjectReportable(reportable) {
    reportable.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
    reportable.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);

    const url = `${Config.taskServerUrl}/subject/selfReporting/updateOrInvalidate`;

    return (await axios.post(url, reportable, addWithCredentials())).data;
}

export function updateSubjectReportables(hashCode, reportables) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            const url = `${Config.personServerUrl}/person/selfReporting/updateInfo/${hashCode}`;

            axios.post(url, reportables).then((response) => {
                dispatch({
                    type: FETCH_SUBJECT_REPORTABLES,
                    payload: response
                });
                resolve(response.data);
            }).catch(reject);
        })
    }
}