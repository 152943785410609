/**
 * Created by shivaraman on 5/10/17.
 */
import axios from 'axios';
import {addTokenHeader, refreshToken, TOKEN_KEY} from './auth_actions';
import {CUR_ORG} from "./session_constants";

// To get the configuration so we can load properties
const Config = require('Config');

export async function fetchDocControls() {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/docControl/get`;

        const response = await axios.get(reqUrl, addTokenHeader());
        return response.data;
    }
}

export async function fetchForeignTravels() {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/generateForeignTravelReportForDiss`;

        const response = await axios.get(reqUrl, addTokenHeader());
        return response.data;
    }
}

export async function fetchDocControl(docControlId) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/docControl/${docControlId}/get`;

        const response = await axios.get(reqUrl, addTokenHeader());
        return response.data;
    }
}

export async function createDocControl(docControl){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        docControl.orgId = sessionStorage.getItem(CUR_ORG);
        const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/docControl/create`;

        const response = await axios.post(reqUrl, docControl, addTokenHeader());
        return response.data;
    }
}

export async function updateDocControl(docControl){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        docControl.orgId = sessionStorage.getItem(CUR_ORG);
        const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/docControl/update`;

        const response = await axios.post(reqUrl, docControl, addTokenHeader());
        return response.data;
    }
}

export async function bulkUploadDocControl(docControls){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        for (let docControl of docControls){
            docControl.orgId = sessionStorage.getItem(CUR_ORG);
        }
        const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/docControl/bulkUpload`;

        const response = await axios.post(reqUrl, docControls, addTokenHeader());
        return response.data;
    }
}

export async function destroyDocControl(docControl){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/docControl/destroy`;

        const response = await axios.post(reqUrl, docControl, addTokenHeader());
        return response.data;
    }
}

export async function changeStorage(docControl){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/docControl/${docControl.id}/changeStorage`;

        const response = await axios.post(reqUrl, docControl, addTokenHeader());
        return response.data;
    }
}