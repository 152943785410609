import axios from "axios";
import {addTokenHeader, refreshToken, TOKEN_KEY} from "./auth_actions";
import {CUR_ORG} from "./session_constants";
import {getDataFromDocsAsync} from "./doc_actions";
import {CommonUtils} from "./common_utils";

const Config = require('Config')

export async function fetchTemplates(errorCallback) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/signature/templates/${sessionStorage.getItem(CUR_ORG)}/list`;
        const response = await axios.get(url, addTokenHeader()).catch((error) => errorCallback(error));
        return response.data;
    }
}

export async function notifySubject(newHireIssue){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        let docsSubmitForTask = [];
        if (newHireIssue.instructionDocs && newHireIssue.instructionDocs.length > 0) {
            const docs = await getDataFromDocsAsync(newHireIssue.instructionDocs);
            docsSubmitForTask = CommonUtils.createDocForUploadingForFormik(docs);
            newHireIssue.instructionDocs = docsSubmitForTask;
        }
        let url = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/newHireIssue/notifySubject`;
        let response = await axios.post(url, newHireIssue, addTokenHeader());
        return response;
    }
}

export async function rejectDocs(newHireIssue){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        let url = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/newHireIssue/rejectDocs`;
        axios.post(url, newHireIssue, addTokenHeader());
    }
}

export async function remindSubject(newHireIssue){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        let url = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/newHireIssue/remindSubject`;
        axios.post(url, newHireIssue, addTokenHeader());
    }
}

export async function createNewHireRequest(subjects2Hire){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        let url = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/newHireIssue/create`;
        axios.post(url, subjects2Hire, addTokenHeader());
    }
}

export async function createClearanceCheckRequest(subjects2Hire){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        let url = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/clearanceCheckIssue/create`;
        axios.post(url, subjects2Hire, addTokenHeader());
    }
}

export async function completeClearanceCheck(clearanceCheckIssue){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        let url = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/clearanceCheckIssue/complete`;
        const response = await axios.post(url, clearanceCheckIssue, addTokenHeader());
        return response;
    }
}

export async function cancelClearanceCheck(clearanceId){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        let url = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/clearanceCheckIssue/${clearanceId}/cancel`;
        const response = await axios.delete(url, addTokenHeader());
        return response;
    }
}

export async function approveNewHire(subjects2Hire){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        let url = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/newHireIssue/approve`;
        axios.post(url, subjects2Hire, addTokenHeader());
    }
}
