import axios from "axios";
import {addTokenHeader, refreshToken, TOKEN_KEY} from "./auth_actions";
import {CUR_ORG} from "./session_constants";

const Config = require('Config')

export async function fetchAvailableStrlngPackages(errorCallback) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/bgCheck/strlngPackages/list`;
        const response = await axios.get(url, addTokenHeader()).catch((error) => errorCallback(error));
        return response.data;
    }
}

export async function fetchAvailableStrlngPackagesForOrg(errorCallback) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/bgCheck/strlngPackages/${sessionStorage.getItem(CUR_ORG)}/list`;
        const response = await axios.get(url, addTokenHeader()).catch((error) => errorCallback(error));
        return response.data;
    }
}

export async function fetchAllScreenings(errorCallback) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/bgCheck/screenings/${sessionStorage.getItem(CUR_ORG)}/list`;
        const response = await axios.get(url, addTokenHeader()).catch((error) => errorCallback(error));
        return response.data;
    }
}

export async function fetchScreeningsUsage() {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/bgCheck/screenings/${sessionStorage.getItem(CUR_ORG)}/usage`;
        const response = await axios.get(url, addTokenHeader());
        return response.data;
    }
}

export async function fetchScreening(screeningId, errorCallback) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/bgCheck/org/${sessionStorage.getItem(CUR_ORG)}/screening/${screeningId}`;
        const response = await axios.get(url, addTokenHeader()).catch((error) => errorCallback(error));
        return response.data;
    }
}

export async function initiateScreening(subjectId, packageId, onSuccess, errorCallback){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        let req = {
            subjectId,
            packageId
        }
        let url = `${Config.personServerUrl}/bgCheck/initiateScreening/org/${sessionStorage.getItem(CUR_ORG)}`;
        try {
            await axios.post(url, req, addTokenHeader());
            onSuccess();
        } catch (error){
            errorCallback(error);
        }
    }
}

export async function updatePackagesWithOrgRel(packages){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        let url = `${Config.personServerUrl}/bgCheck/packages/orgRelUpdate`;
        await axios.post(url, packages, addTokenHeader());
    }
}
