import axios from "axios";
import {addTokenHeader, refreshToken, TOKEN_KEY} from "./auth_actions";
import {CUR_ORG} from "./session_constants";
import {getDataFromDocsAsync} from "./doc_actions";
import {CommonUtils} from "./common_utils";

const Config = require('Config')

export async function fetchNotifications() {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const response = await axios.get(`${Config.personServerUrl}/notification/org/${sessionStorage.getItem(CUR_ORG)}/list`, addTokenHeader());
        // console.log('Notifications are ', response);
        return response.data;
    }
}

export async function getNotificationPreferences() {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const response = await axios.get(`${Config.personServerUrl}/user/notificationPreferences/${sessionStorage.getItem(CUR_ORG)}/get`, addTokenHeader());
        return response.data;
    }
}

export async function updatePreferences(notificationPreferenceMap){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        let url = `${Config.personServerUrl}/user/notificationPreferences/${sessionStorage.getItem(CUR_ORG)}/update`;
        let response = await axios.post(url, notificationPreferenceMap, addTokenHeader());
        return response;
    }
}
