/**
 * Created by shivaraman on 6/2/17.
 */
import axios from 'axios';

import {INVALID_TOKEN, LOGIN_SUCCESS, LOGOUT_SUCCESS} from './action_constants';
import {onError} from "./error_actions";
import {setOrg} from "./org_actions";

// To get the configuration so we can load properties
const Config = require('Config');
export const TOKEN_KEY = 'token';
export const SECOND_FACTOR = 'secondFactor';
export const USER_NAME = 'userName';
export const USER_ID = 'userId';
export const ROLES = 'roles';
export const SUBJECT_ID = 'subjectId';
export const DISPLAY_NAME = 'dispName';
export const ORG_NAME = 'ORG_NAME';
export const VERIFIED_EMAIL = "verifiedEmail";
export const REPORTING_PASS_CODE = "reportingPassCode";
export const CHATBOT_TOKEN = "chatbotToken";

export function loginUser(creds, callBack, onError) {
    return function(dispatch) {
        const url = `${Config.personServerUrl}/auth/login`;
        let type = LOGIN_SUCCESS;
        const response =
            axios.post(url, creds).then(response => {
                callBack(response.data);
                dispatch({
                    type: type,
                    payload: response
                });
            }).catch(onError);

        return response;
    }
}

export function authUserForSecondFactor(creds, callBack, onError) {//, callBack){
    const url = `${Config.personServerUrl}/auth/secondFactor`;
    let type = LOGIN_SUCCESS;
    const response = //return function (dispatch) {
        axios.post(url, creds, {headers: {'Authorization': creds.token}}).then(response => {
            sessionStorage.setItem(TOKEN_KEY, response.data.token);
            sessionStorage.setItem(USER_NAME, response.data.userName);
            sessionStorage.setItem(SECOND_FACTOR, "true"); // Doest matter what this is set to. If this is set the UI can act as if
            // second factor is authenticated
            if (response.data.userObject) {
                // setOrgIfAdmin(response.data.userObject);
                sessionStorage.setItem(ROLES, JSON.stringify(response.data.userObject.roles));
                sessionStorage.setItem(USER_ID, response.data.userObject.id);
                if (response.data.userObject.subject) {
                    sessionStorage.setItem(SUBJECT_ID, response.data.userObject.subject.id);
                    sessionStorage.setItem(DISPLAY_NAME, `${response.data.userObject.subject.personFirstName} ${response.data.userObject.subject.personLastName}`)
                }
            }
            callBack(response.data);
            dispatch({
                type: type,
                payload: response
            });
        }).catch(onError); // when promise returns then we call the callback

    //}
    return response;
}

// if the logged in user is sys admin, they should have the TruVetting org assigned. Set that as the current org
function setOrgIfAdmin(userObject){
    if (userObject.roles && userObject.roles.length){
        // is the person a sysadmin
        let isSysAdmin = false;
        for (let eachRole of userObject.roles){
            if (eachRole.role === 'SYS_ADMIN'){
                isSysAdmin = true;
                break;
            }
        }
        if (isSysAdmin){
            // get their only org and set it
            for (let eachRole of userObject.roles){
                if (eachRole.role === 'ORG_ADMIN' || eachRole.role === 'PRG_MGR'){
                    setOrg(eachRole.owningEntityId);
                    break;
                }
            }
        }
    }
}

export function logoutUser() {
    sessionStorage.clear();
    // .removeItem(TOKEN_KEY);
    window.location.href = `${Config.clientUrl}`;
    return {
        type: LOGOUT_SUCCESS
    }
}

export function addTokenHeader() {
    return {
        headers: {'Authorization': sessionStorage.getItem(TOKEN_KEY)}
    }
}

// This is to send the cookie with the request for self reporting
export function addWithCredentials() {
    return {
        'withCredentials': 'true'
    }
}

export function refreshToken() {
    // If token is not expired then we refresh the token every time. The tokens expire every 5 minutes
    // or whatever the idle time expiry length is
    // this way new tokens are generated for every request and the tokens extend for a little longer
    const url = `${Config.personServerUrl}/auth/refresh`;
    axios.get(url, addTokenHeader()).then(response => {
        sessionStorage.setItem(TOKEN_KEY, response.data.token);
    });
}

export function tokenRefreshRequest(){
    return function (dispatch) {
        refreshToken(dispatch);
    }
}

export function refreshSelfReportingPassCode(){
    // Refresh self reporting code
    const url = `${Config.personServerUrl}/person/selfReporting/passCodeRefresh`;
    const request = {
        passCode: sessionStorage.getItem(REPORTING_PASS_CODE),
        emailAddress: sessionStorage.getItem(VERIFIED_EMAIL)
    };
    axios.post(url, request, addWithCredentials()).then((response) => {
        // Not doing anything here atm
        // console.log("Refreshed Token", response.data);
    });
}