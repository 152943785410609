/**
 * Created by pshivaraman on 3/23/18.
 */
import axios from "axios";
import {CUR_ORG} from "./session_constants";
import {getDataFromDocs, getDataFromDocsAsync} from "./doc_actions";
import {addTokenHeader, refreshToken, ROLES, SUBJECT_ID, TOKEN_KEY} from "./auth_actions";
import {
    ComplianceType,
    CREATE_ORG,
    FETCH_CUR_ORG, FETCH_MANAGED_ORGS,
    FETCH_ORG,
    FETCH_ORGS,
    FETCH_ORGS_BY_USER, FETCH_ORGS_SEARCH,
    UPDATE_ORG
} from "./action_constants";
import {getActiveOrgPersons} from "./subject_actions";
import {CommonUtils} from "./common_utils";


// To get the configuration so we can load properties
const Config = require('Config');

/**
 * Extracts underlying Org from OrgUI and combines the two.
 * @param org OrgUI object from axios
 * @returns {*} Combined Org and OrgUI
 */
export function getOrgObj(org) {
    if (org) {
        return org.org ? {...org.org, ...org} : org;
    }
    return null;
}

export function processEntitiesForCompliance(entities = [], byDocType = false, docType = "") {
    // Get Doc Type without Prefix
    let docTypeWithoutPrefix = docType;
    if (docTypeWithoutPrefix.includes(':')) {
        docTypeWithoutPrefix = docTypeWithoutPrefix.split(":")[1];
    }

    // Classify Entities
    let complaintEntities = [];
    let nonCompliantEntities = [];
    if (entities) {
        for (let entity of entities) {
            if ((entity.docTypesPending && entity.docTypesPending.length > 0)) {
                // Filter by docs if required
                if (byDocType) {
                    let matchFound = false;
                    for (let docTypeStr of entity.docTypesPending) {
                        let docTypeFromEntity = docTypeStr;
                        if (docTypeFromEntity.includes(':')) {
                            docTypeFromEntity = docTypeFromEntity.split(':')[1];
                        }
                        if (docTypeFromEntity === docTypeWithoutPrefix) {
                            matchFound = true;
                            break;
                        }
                    }
                    if (matchFound) {
                        nonCompliantEntities.push(entity);
                    }
                } else {
                    nonCompliantEntities.push(entity);
                }
            } else {
                complaintEntities.push(entity);
            }
        }
    }

    return {
        all: entities,
        compliant: complaintEntities,
        nonCompliant: nonCompliantEntities
    }
}

export function fetchEntitiesForCompliance(entityType, byDocType = false, docType = "") {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            let url = `${Config.personServerUrl}/person/list/byOrgActiveFor/${sessionStorage.getItem(CUR_ORG)}`;
            switch (entityType) {
                case "SUBJECT":
                    url = `${Config.personServerUrl}/person/list/byOrgActiveFor/${sessionStorage.getItem(CUR_ORG)}`;
                    break;
                case "FACILITY":
                    url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/facility/list`;
                    break;
                case "CONTRACT":
                    url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/contract/list`;
                    break;
            }

            axios.get(url, addTokenHeader()).then((response) => {
                const entities = response.data;

                dispatch({
                    type: "FETCH_ENTITIES_FOR_COMPLIANCE",
                    payload: processEntitiesForCompliance(entities, byDocType, docType)
                })
            });
        }
    }
}

export function createOrg(org) {
    return async function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/create`;
            const sendOrgObj = {
                org: org
            };

            const docs = await getDataFromDocsAsync(org.logoDoc);

            if (docs[0]) {
                // Only expecting one file to come back
                sendOrgObj.logo = {
                    document: {
                        name: `${org.name} Logo`,
                        type: "OTHER"
                    },
                    revisions: [{
                        number: 1,
                        uploader: sessionStorage.getItem(SUBJECT_ID),
                        fileName: docs[0].name,
                        fileType: docs[0].type,
                        data: docs[0].data,
                    }],
                };
            }

            try {
                const response = await axios.post(url, sendOrgObj, addTokenHeader());

                dispatch({
                    type: CREATE_ORG,
                    payload: response
                });
            } catch (error) {
                if (error && error.response && error.response.status) {
                    switch (error.response.status) {
                        case 400:
                            throw (error.response.data.message);
                    }
                }
                onError(error);
            }
        }
    }
}

export function deleteOrg(orgId, callBack) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${orgId}/remove`;
            axios.delete(url, addTokenHeader()).then(response => {
                callBack()
            })
        }
    }
}

export function updateOrg(org, orgId, callback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/update`;
            console.log(org);
            const sendOrgObj = {
                org: org
            };
            sendOrgObj.org.id = orgId;

            getDataFromDocs(org.logoDoc, (docs) => {
                if (docs[0]) {
                    // Only expecting one file to come back
                    sendOrgObj.logo = {
                        document: {
                            name: `${org.name} Logo`,
                            type: "logo",
                        },
                        revisions: [{
                            number: 1,
                            uploader: sessionStorage.getItem(SUBJECT_ID),
                            fileName: docs[0].name,
                            fileType: docs[0].type,
                            data: docs[0].data,
                        }],
                    };
                }
                axios.post(url, sendOrgObj, addTokenHeader()).then(response => {
                    callback();
                    dispatch({
                        type: UPDATE_ORG,
                        payload: response
                    })
                })
            });
        }
    }
}

export function updateOrgForSigTemplates(org, orgId, callback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${orgId}/updateSigTemplates`;
            console.log(org);
            axios.post(url, org, addTokenHeader()).then(response => {
                callback();
                dispatch({
                    type: UPDATE_ORG,
                    payload: response
                })
            })
        }
    }
}

export async function addSigTemplate(orgId, signatureTemplate){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        let url = `${Config.personServerUrl}/org/${orgId}/sigTemplates/add`;
        let response = await axios.post(url, signatureTemplate, addTokenHeader());
        return response;
    }
}

export async function updateSignatureTemplate(orgId, signatureTemplate){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        let url = `${Config.personServerUrl}/org/${orgId}/sigTemplate/update`;
        let response = await axios.post(url, signatureTemplate, addTokenHeader());
        return response;
    }
}

export async function fetchOrgWithSummaryForAccessAndEligibility(orgId){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        let url = `${Config.personServerUrl}/org/${orgId}/withAccessEligSummary`;
        let response = await axios.get(url, addTokenHeader());
        return response;
    }
}

export function fetchOrg(id) {
    console.log('Fetching org with id ', id);
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${id}`;

            axios.get(url, addTokenHeader()).then(response => {
                dispatch({
                    type: FETCH_ORG,
                    payload: response
                })
            })
        }
    }
}

export function fetchCurOrg(id = sessionStorage.getItem(CUR_ORG)) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${id}`;

            axios.get(url, addTokenHeader()).then(response => {
                dispatch({
                    type: FETCH_CUR_ORG,
                    payload: response
                })
            })
        }
    }
}

export function fetchOrgs() {
    return function (dispatch) {
        refreshToken(dispatch);
        let userRoles = sessionStorage.getItem(ROLES);
        if (userRoles !== '[]'){
            if (sessionStorage.getItem(TOKEN_KEY)) {
                const url = `${Config.personServerUrl}/org/list`;
                axios.get(url, addTokenHeader()).then(response => {
                    dispatch({
                        type: FETCH_ORGS,
                        payload: response
                    });
                });
            }
        } else {
            // Added so users who have no roles do not get 403 forbidden error in the UI
            dispatch({
                type: FETCH_ORGS,
                payload: {data: []}
            });

        }
    }
}

export function fetchSysOrgs() {
    return function (dispatch) {
        refreshToken(dispatch);
        let userRoles = sessionStorage.getItem(ROLES);
        if (userRoles !== '[]'){
            if (sessionStorage.getItem(TOKEN_KEY)) {
                const url = `${Config.personServerUrl}/sys/org/list`;
                axios.get(url, addTokenHeader()).then(response => {
                    dispatch({
                        type: FETCH_ORGS,
                        payload: response
                    });
                });
            }
        } else {
            // Added so users who have no roles do not get 403 forbidden error in the UI
            dispatch({
                type: FETCH_ORGS,
                payload: {data: []}
            });

        }
    }
}

export async function fetchOrgsAndWait(errorCallback) {
    let userRoles = sessionStorage.getItem(ROLES);
    if (userRoles !== '[]') {
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/list`;
            try {
                const response = await axios.get(url, addTokenHeader());
                if (response) {
                    return response.data;
                }
            } catch (error){
                errorCallback(error);
            }
        }
    } else {
        // Added so users who have no roles do not get 403 forbidden error in the UI
        return [];
    }
}

export function fetchOrgsByName(name = '.*') {
    if (name === '.*' || name === '') {
        return fetchOrgs();
    }
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/list/byName/${name}`;
            axios.get(url, addTokenHeader()).then(response => {
                dispatch({
                    type: FETCH_ORGS,
                    payload: response
                });
            });
        }
    }
}

export function fetchOrgsByCageCode(cageCode = '.*') {
    if (cageCode === '.*' || cageCode === '') {
        return fetchOrgs();
    }
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/list/byCageCode/${cageCode}`;
            axios.get(url, addTokenHeader()).then(response => {
                dispatch({
                    type: FETCH_ORGS,
                    payload: response
                });
            });
        }
    }
}

export function fetchManagedOrgs(orgId = sessionStorage.getItem(CUR_ORG)) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/list/byManagingOrg/${orgId}`;
            axios.get(url, addTokenHeader()).then(response => {
                dispatch({
                    type: FETCH_MANAGED_ORGS,
                    payload: response
                })
            });
        }
    }
}

export function assignManagingOrg(managingOrgId, managedOrgId, callback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${managedOrgId}/assignManagingOrg/${managingOrgId}`;
            axios.post(url, {}, addTokenHeader()).then((response) => {
                callback();

                dispatch({
                    type: FETCH_ORG,
                    payload: response
                });
            });
        }
    }
}

export function unassignManagingOrg(managedOrgId, callback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${managedOrgId}/unassignManagingOrg`;
            axios.delete(url, addTokenHeader()).then(response => {
                callback();

                dispatch({
                    type: FETCH_ORG,
                    payload: response
                });
            });
        }
    }
}

export function fetchAllUnmanagedOrgs() {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/list/byNoManagingOrg`;
            axios.get(url, addTokenHeader()).then(response => {

                dispatch({
                    type: FETCH_ORGS_SEARCH,
                    payload: response
                })
            });
        }
    }
}

export function setOrg(org) {
    if (org) {
        sessionStorage.setItem(CUR_ORG, org);
        fetchCurOrg(org);
    }
}