import axios from "axios";
import {
    FETCH_DOCUMENTS,
    SELF_REPORTING_DONE,
    VISIT_APPROVED,
    VISIT_ISSUE_FETCHED,
    VISIT_ISSUES_FETCHED, VISIT_REJECTED,
    VISITS_FETCHED
} from "./action_constants";
import {
    addTokenHeader,
    addWithCredentials,
    refreshToken,
    REPORTING_PASS_CODE,
    TOKEN_KEY,
    VERIFIED_EMAIL
} from "./auth_actions";
import {CUR_ORG} from "./session_constants";

const Config = require('Config')

export async function fetchVisits() {
    const url = `${Config.taskServerUrl}/subject/selfReporting/visit/getList`;
    let req = {
        emailAddress : sessionStorage.getItem(VERIFIED_EMAIL),
        passCode : sessionStorage.getItem(REPORTING_PASS_CODE)
    }
    const response = await axios.post(url, req, addWithCredentials());
    return response.data;
}

export async function fetchOrgsToPick() {
    const url = `${Config.taskServerUrl}/subject/selfReporting/visit/getOrgs`;
    let req = {
        emailAddress : sessionStorage.getItem(VERIFIED_EMAIL),
        passCode : sessionStorage.getItem(REPORTING_PASS_CODE)
    }
    const response = await axios.post(url, req, addWithCredentials());
    return response.data;
}

export async function fetchVisitIssueforStatus(status) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        try {
            const url = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/visitIssue/${status}/list`;
            const response = await axios.get(url, addTokenHeader());
            console.log("Visit Issue is:", response)
            return response.data;
        } catch (error) {
            return null;
        }
    }
}


export function fetchVisitIssues(status) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {

            const url = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/visitIssue/${status}/list`;
            axios.get(url, addTokenHeader()).then(response => {
                dispatch({
                    type: VISIT_ISSUES_FETCHED,
                    payload: response
                });
            });
        }
    }
}

export function fetchVisitIssue(visitIssueId) {
    return function (dispatch) {
        refreshToken();
        if (sessionStorage.getItem(TOKEN_KEY)) {

            const url = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/visitIssue/${visitIssueId}`;
            axios.get(url, addTokenHeader()).then(response => {
                dispatch({
                    type: VISIT_ISSUE_FETCHED,
                    payload: response
                });
            });
        }
    }
}

export async function fetchVisitsByQuery(page = -1, sizePerPage = -1, officeNameFilter = "", securityPocNameFilter = "",
                                   classificationLevelFilter=null, purposeFilter = "",
                                   officeNameSort = 0, startDateSort = 0, endDateSort = 0) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/visit/list/byQuery`;

        const queryObj = {
            officeNameFilter,
            securityPocNameFilter,
            classificationLevelFilter,
            purposeFilter,
            officeNameSort,
            startDateSort,
            endDateSort,
            page,
            sizePerPage
        };

        console.log('Visit Query Obj is ', queryObj)
        const response = axios.post(url, queryObj, addTokenHeader());
        return response;
    } else {
        return null;
    }
}

export async function createVisit(visit) {
    const url = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/visit/create`;
    return axios.post(url, visit, addTokenHeader());
}

export function reportVisit(visit, onSuccess, onError){
    // Return an asynchronous function with the redux dispatch function.
    return async (dispatch) => {
        // Attach email address
        visit.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);
        visit.reporterEmail = sessionStorage.getItem(VERIFIED_EMAIL);
        visit.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
        const url = `${Config.taskServerUrl}/subject/selfReporting/reportVisit`;

        // Catch server errors
        try {
            const response = await axios.post(url, visit, addWithCredentials());
            onSuccess();
            // Redux dispatch
            dispatch({
                type: SELF_REPORTING_DONE,
                payload: response
            });
            return response.data;
        } catch (error) {
            onError(error);
        }
    };
}

export function modifyVisit(visit, onSuccess, onError){
    return async (dispatch) => {
        // Attach email address
        visit.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);
        visit.reporterEmail = sessionStorage.getItem(VERIFIED_EMAIL);
        visit.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
        const url = `${Config.taskServerUrl}/subject/selfReporting/modifyVisit`;

        // Catch server errors
        try {
            const response = await axios.post(url, visit, addWithCredentials());
            onSuccess();
            // Redux dispatch
            dispatch({
                type: SELF_REPORTING_DONE,
                payload: response
            });
            return response.data;
        } catch (error) {
            onError(error);
        }
    };
}

export async function fetchVisitIssueForFixing(emailCode) {
    const url = `${Config.taskServerUrl}/subject/selfReporting/${emailCode}/visit/get`;
    try {
        const response = await axios.get(url, addWithCredentials());
        return response.data;
    } catch (error) {
        return null;
    }
}


export async function fixVisit(visit, emailCode, onSuccess, onError){
    // Return an asynchronous function with the redux dispatch function.
    return async (dispatch) => {
        const url = `${Config.taskServerUrl}/subject/selfReporting/${emailCode}/visit/fix`;

        // Catch server errors
        try {
            const response = await axios.post(url, visit, addWithCredentials());
            onSuccess();
            // Redux dispatch
            dispatch({
                type: SELF_REPORTING_DONE,
                payload: response
            });
            return response.data;
        } catch (error) {
            onError(error);
        }
    };
}

export function approveVisit(visit, visitIssueId, onSuccess, onError){
    // Return an asynchronous function with the redux dispatch function.
    return async (dispatch) => {
        refreshToken(dispatch);
        const url = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/visit/${visitIssueId}/approve`;

        // Catch server errors
        try {
            const response = await axios.post(url, visit, addTokenHeader());
            onSuccess();
            // Redux dispatch
            dispatch({
                type: VISIT_APPROVED,
                payload: response
            });
            return response.data;
        } catch (error) {
            onError(error);
        }
    };
}

export function rejectVisit(visit, visitIssueId, onSuccess, onError){
    // Return an asynchronous function with the redux dispatch function.
    return async (dispatch) => {
        refreshToken(dispatch);
        const url = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/visit/${visitIssueId}/reject`;
        console.log('Rejection url ', url);

        // Catch server errors
        try {
            const response = await axios.post(url, visit, addTokenHeader());
            onSuccess();
            // Redux dispatch
            dispatch({
                type: VISIT_REJECTED,
                payload: response
            });
            return response.data;
        } catch (error) {
            onError(error);
        }
    };
}
