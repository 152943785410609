/**
 * Created by shivaraman on 6/13/2017.
 */

import React from 'react';
import {logoutUser} from "./auth_actions";
import {INVALID_TOKEN} from "./action_constants";
import * as ReactDOM from "react-dom";
import {showErrorGrowl} from "./msg_actions";
import {myStore} from "../index";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function errorHandler() {
    return function (dispatch) {
        // Logout
        logoutUser();
        // return payload for logout
        dispatch({
            type: INVALID_TOKEN,
            payload: {}
        });
    }
}

export function unauthHandler() {
    // Using the store to dispatch without creating a component
    showErrorGrowl("Unauthorized", "You are not authorized to perform this action")(myStore.dispatch);
}

export function sessionTimeoutHandler() {
    const timeoutModal =
        <Modal show backdrop={'static'}>
            <Modal.Header>
                <Modal.Title>Session Timeout</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Your Session Has Been Timed Out</h4>
                <p>To protect your data, the system automatically times out your session after a period of inactivity.
                    You will automatically be logged out and taken to the log in page.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={errorHandler()}>OK</Button>
            </Modal.Footer>
        </Modal>;
    ReactDOM.render(timeoutModal, document.querySelector("#container"));
}

export function unhandledExceptionHandler(error) {
    // Using the store to dispatch without creating a component
    showErrorGrowl("Server Error", "A server error occurred!")(myStore.dispatch);
    // const unhandledModal =
    //     <Modal show>
    //         <Modal.Header>
    //             <Modal.Title>Unhandled Exception Occurred!</Modal.Title>
    //         </Modal.Header>
    //         <Modal.Body>
    //             <h4>Exception Details</h4>
    //             <p>An unhandled exception occurred. You will now be logged out.</p>
    //             <p>{error && error.stack ? error.stack : ""}</p>
    //         </Modal.Body>
    //         <Modal.Footer>
    //             <Button onClick={errorHandler()}>Log Out</Button>
    //         </Modal.Footer>
    //     </Modal>;
    // ReactDOM.render(unhandledModal, document.querySelector("#container"));
}