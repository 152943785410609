/**
 * Created by shivaraman on 5/30/18.
 */
import axios from "axios";
import {CREATE_PERSONS, CSV_UPLOAD, CSV_UPLOAD_ASSETS, REMOVE_PERSONS} from "./action_constants";
import excel2PropMap from '../mappingFiles/excel2PropertyMap.json'
import {getDocDetail} from './doc_actions'
import {addTokenHeader, refreshToken, TOKEN_KEY} from './auth_actions';
import {CUR_ORG} from "./session_constants";
import {CommonUtils} from "./common_utils";

const NEW_LINE = '\n'
// To get the configuration so we can load properties
const Config = require('Config');

// constants to identify the doc type being uploaded
export const DISS_ELIGIBILITY_DOC = "DISS Eligibility Doc";
export const EMAIL_DOC = "Email Doc";
export const JPAS_ELIGIBILITY_DOC = "JPAS Eligibility Doc";
export const HR_IMPORT_DOC = "HR Import Doc";
export const BADGE_AND_BACKGROUND_DOC = "Badge and Background Doc";
export const DONNA_MASTER_ROSTER_DOC = "Donna Master Roster Doc";
export const ASSET_DOC = "Asset Doc";
export const DOC_CONTROL_DOC = "Document Control Doc";

/**
 * Adds fields from excel with name and value to person object
 * @param personObj
 * @param columnName
 * @param columnValue
 */
function addProperty2Person(personObj, columnName, columnValue, docType) {
    let propertyName = null;
    if (docType === JPAS_ELIGIBILITY_DOC) {
        propertyName = excel2PropMap.jpasEligMap[columnName.toLowerCase()]
    } else if (docType === DISS_ELIGIBILITY_DOC) {
        propertyName = excel2PropMap.dissEligMap[columnName.toLowerCase()]
    } else if (docType === HR_IMPORT_DOC) {
        propertyName = excel2PropMap.hrDataMap[columnName.toLowerCase()]
    } else if (docType === EMAIL_DOC) {
        propertyName = excel2PropMap.emailMap[columnName.toLowerCase()]
    } else if (docType === BADGE_AND_BACKGROUND_DOC) {
        propertyName = excel2PropMap.badgeAndBGMap[columnName.toLowerCase()]
    } else if (docType === DONNA_MASTER_ROSTER_DOC) {
        propertyName = excel2PropMap.donnaMasterRosterMap[columnName.toLowerCase()]
    } else if (docType === ASSET_DOC) {
        propertyName = excel2PropMap.assetMap[columnName.toLowerCase()]
    } else if (docType === DOC_CONTROL_DOC) {
        propertyName = excel2PropMap.docControlMap[columnName.toLowerCase()]
    }
    if (propertyName) {
        // only if the map is for that field
        // Is there a mapping? If so get the mapped value to allowable values
        if (excel2PropMap.mappingList && excel2PropMap.mappingList[propertyName] && excel2PropMap.mappingList[propertyName][columnValue]) {
            // there is a mapping for it
            personObj[propertyName] = excel2PropMap.mappingList[propertyName][columnValue];
            return;
        }

        if (excel2PropMap.columnsThatNeedSpacesTrimmedWithin.includes(propertyName) && columnValue !== undefined) {
            // ok spaces need to be trimmed
            personObj[propertyName] = columnValue.replace(/ +/g, "");
        } else {
            personObj[propertyName] = columnValue;
        }
    }
}

function isHeaderAMatch(csvColumnArr, columnToMatch){
    let columnToMatchLowercase = columnToMatch.toLowerCase().replaceAll(' ','');
    columnToMatchLowercase = columnToMatchLowercase.replace(/[\n\r]+/g, '');
    for (let eachColumnFromCsv of csvColumnArr){
        let replacedCsvColumnVal = eachColumnFromCsv.toLowerCase().replaceAll(' ','').replaceAll(/[\n\r]+/g, '');
        if (replacedCsvColumnVal.localeCompare(columnToMatchLowercase) === 0){
            return true;
        }
    }
    return false;
}

function detectHeaderLineNumber(csvContentsArray, docType) {
    let objectEntriesArr = [];
    if (docType === JPAS_ELIGIBILITY_DOC) {
        objectEntriesArr = Object.entries(excel2PropMap.jpasEligMap);
    } else if (docType === DISS_ELIGIBILITY_DOC) {
        objectEntriesArr = Object.entries(excel2PropMap.dissEligMap);
    } else if (docType === HR_IMPORT_DOC) {
        objectEntriesArr = Object.entries(excel2PropMap.hrDataMap);
    } else if (docType === EMAIL_DOC) {
        objectEntriesArr = Object.entries(excel2PropMap.emailMap);
    } else if (docType === BADGE_AND_BACKGROUND_DOC) {
        objectEntriesArr = Object.entries(excel2PropMap.badgeAndBGMap);
    } else if (docType === DONNA_MASTER_ROSTER_DOC) {
        objectEntriesArr = Object.entries(excel2PropMap.donnaMasterRosterMap);
    } else if (docType === ASSET_DOC) {
        objectEntriesArr = Object.entries(excel2PropMap.assetMap);
    } else if (docType === DOC_CONTROL_DOC) {
        objectEntriesArr = Object.entries(excel2PropMap.docControlMap);
    }
    if (objectEntriesArr && objectEntriesArr.length > 0 && objectEntriesArr[0].length > 0) {
        let index = 0;
        for (let eachLine of csvContentsArray) {
            let headerFound = true;
            for (let [eachEntry, Value] of objectEntriesArr) {
                console.log('Header check ', eachLine)
                if (!isHeaderAMatch(eachLine.split(','), eachEntry)){//eachLine.toLowerCase().includes(eachEntry)) {
                    headerFound = false;
                    break;
                }
            }
            if (headerFound) {
                return index;
            }
            index++;
        }
    }

    return csvContentsArray.length - 1;
}

// Return array of string values, or NULL if CSV string not well formed.
function CSVtoArray(text) {
    var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
    var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
    // Return NULL if input string is not well formed CSV string.
    if (!re_valid.test(text)) return null;
    var a = [];                     // Initialize array to receive values.
    text.replace(re_value, // "Walk" the string using replace with callback.
        function (m0, m1, m2, m3) {
            // Remove backslash from \' in single quoted values.
            if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
            // Remove backslash from \" in double quoted values.
            else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
            else if (m3 !== undefined) a.push(m3);
            return ''; // Return empty string.
        });
    // Handle special case of empty last value.
    if (/,\s*$/.test(text)) a.push('');
    return a;
};

function isLineEmpty(line) {
    let trimmedLine = line.replaceAll(',', '').replaceAll(' ', '').replaceAll('\n', '')
        .replaceAll('\r', '').replaceAll('\t', '');
    if (!trimmedLine || trimmedLine.length === 0) {
        return true;
    }
    return false;
}

export function extractCSVData(doc, docType, fillPseudoSsn, callback) {
    return function (dispatch) {
        let generatePseudoSsn = false;
        if (fillPseudoSsn && Array.isArray(fillPseudoSsn) && fillPseudoSsn.length === 1 && fillPseudoSsn[0] === "on") {
            generatePseudoSsn = true;
            console.log('Generate ssn set');
        }
        if (doc.preview) { // If it's a new document
            let promise = getDocDetail(doc.preview);
            let personList = []

            promise.then((result) => {
                let csvContents = new Buffer(result.data, 'binary').toString().split(NEW_LINE)
                console.log('Looking for header line in ', csvContents)
                let headerLineNumber = detectHeaderLineNumber(csvContents, docType);
                console.log('Header line number is ', headerLineNumber)
                console.log('csvcontents ', csvContents)
                // At least one more line than the header line location
                if (csvContents.length > headerLineNumber) {
                    // place to store the person collections
                    // now get the header line
                    // make sure u split that using comma
                    // let headers = csvContents[headerLineNumber].split(COMMA_SEPARATOR)
                    let headers = CSVtoArray(csvContents[headerLineNumber])
                    csvContents.forEach((eachLine, index) => {
                        if (index > headerLineNumber) {
                            if (!isLineEmpty(eachLine)) {
                                let values = CSVtoArray(eachLine) //eachLine.split(COMMA_SEPARATOR)
                                // Now ensure each successive line has the same number of fields
                                if (values && values.length === headers.length) {
                                    // define a person object
                                    let personObj = {}
                                    personObj['id'] = index;
                                    personObj['generatePseudoSsn'] = generatePseudoSsn;
                                    // ok process the line and create a person record
                                    // if the data doesnt exist for more than 50% of the columsn ditch it
                                    let columnsWithDataCount = 0;
                                    for (let i = 0; i < headers.length; i++) {
                                        if (values[i] && values[i] !== "") {
                                            columnsWithDataCount++;
                                        }
                                        addProperty2Person(personObj, headers[i], values[i], docType)
                                    }
                                    // if ((columnsWithDataCount*100)/headers.length > 50) {
                                    personList.push(personObj);
                                    // }
                                }
                            }
                        }
                    })

                    // add zeroes to ssn if needed
                    for (let subject of personList) {
                        subject.socialSecurityNumber = CommonUtils.zeroOutSsn(subject.socialSecurityNumber);
                    }
                    precheckSubjectsUpload(personList).then((response) => {

                        dispatch({
                            type: CSV_UPLOAD,
                            payload: {
                                data: response.data,
                                documentType: docType
                            }
                        })
                        callback()
                    })
                }
                // get from backend the fields we are interested in
                // this backend field should be a map with fieldName mapped to a method name in the person object
                // create a person object and using the array notation set the property
                // Do this so the temp preview doesnt occupy memory and cause leaks
                window.URL.revokeObjectURL(doc.preview);
            })
        }
    }

}

export function extractCSVDataForEntity(doc, typeOfEntityUploaded=ASSET_DOC, dispatchTypeName=CSV_UPLOAD_ASSETS) {
    return function (dispatch) {
        if (doc.preview) { // If it's a new document
            let promise = getDocDetail(doc.preview);
            let entityList = []

            promise.then((result) => {
                let csvContents = new Buffer(result.data, 'binary').toString().split(NEW_LINE)
                let headerLineNumber = detectHeaderLineNumber(csvContents, typeOfEntityUploaded);
                // At least one more line than the header line location
                if (csvContents.length > headerLineNumber) {
                    // place to store the person collections
                    // now get the header line
                    // make sure u split that using comma
                    // let headers = csvContents[headerLineNumber].split(COMMA_SEPARATOR)
                    let headers = CSVtoArray(csvContents[headerLineNumber])
                    csvContents.forEach((eachLine, index) => {
                        if (index > headerLineNumber) {
                            if (!isLineEmpty(eachLine)) {
                                let values = CSVtoArray(eachLine) //eachLine.split(COMMA_SEPARATOR)
                                // Now ensure each successive line has the same number of fields
                                if (values && values.length === headers.length) {
                                    // define a person object
                                    let entityObj = {}
                                    // ok process the line and create a person record
                                    // if the data doesnt exist for more than 50% of the columsn ditch it
                                    let columnsWithDataCount = 0;
                                    for (let i = 0; i < headers.length; i++) {
                                        if (values[i] && values[i] !== "") {
                                            columnsWithDataCount++;
                                        }
                                        addProperty2Person(entityObj, headers[i], values[i], typeOfEntityUploaded)
                                    }
                                    // if ((columnsWithDataCount*100)/headers.length > 50) {
                                    entityList.push(entityObj);
                                    // }
                                }
                            }
                        }
                    })

                    dispatch({
                        type: dispatchTypeName,
                        payload: {
                            data: entityList
                        }
                    })
                }
            });
            // get from backend the fields we are interested in
            // this backend field should be a map with fieldName mapped to a method name in the person object
            // create a person object and using the array notation set the property
            // Do this so the temp preview doesnt occupy memory and cause leaks
            window.URL.revokeObjectURL(doc.preview);
        }
    }
}

export function precheckSubjectsUpload(subjects) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        let url = `${Config.personServerUrl}/person/org/${sessionStorage.getItem(CUR_ORG)}/personsAndAccess/preCheck`;
        return axios.post(url, subjects, addTokenHeader());
    }
}

export function terminateSubjects(subjects) {

    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            return new Promise((resolve, reject) => {
                let subjectIds = [];
                for (let subject of subjects){
                    subjectIds.push(subject.id);
                }
                let url = `${Config.personServerUrl}/person/org/${sessionStorage.getItem(CUR_ORG)}/subjects/terminate`;
                axios.post(url, subjectIds, addTokenHeader()).then((response) => {
                    resolve();
                    dispatch({
                        type: CREATE_PERSONS,
                        payload: response
                    });
                }).catch(error => {
                    switch (error.response.status) {
                        case 500:
                            reject(error.response.data.message);
                            break;
                    }
                });
            });
        }
    }

}

export function uploadPersons(allSubjects, subjectAndFacilities, docType, facilityId) {

    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            return new Promise((resolve, reject) => {
                let url = `${Config.personServerUrl}/person/org/${sessionStorage.getItem(CUR_ORG)}/DISS/personsAndAccess/${facilityId}`;
                if (docType === JPAS_ELIGIBILITY_DOC) {
                    url = `${Config.personServerUrl}/person/org/${sessionStorage.getItem(CUR_ORG)}/JPAS/personsAndAccess/${facilityId}`;
                } else if (docType === HR_IMPORT_DOC) {
                    url = `${Config.personServerUrl}/person/org/${sessionStorage.getItem(CUR_ORG)}/HR/personsAndAccess/${facilityId}`;
                } else if (docType === EMAIL_DOC) {
                    url = `${Config.personServerUrl}/person/org/${sessionStorage.getItem(CUR_ORG)}/EMAIL/personsAndAccess/${facilityId}`;
                } else if (docType === BADGE_AND_BACKGROUND_DOC) {
                    url = `${Config.personServerUrl}/person/org/${sessionStorage.getItem(CUR_ORG)}/BADGE_AND_BACKGROUND/personsAndAccess/${facilityId}`;
                } else if (docType === DONNA_MASTER_ROSTER_DOC) {
                    url = `${Config.personServerUrl}/person/org/${sessionStorage.getItem(CUR_ORG)}/DONNA_MASTER_ROSTER/personsAndAccess/${facilityId}`;
                }
                axios.post(url, subjectAndFacilities, addTokenHeader()).then((response) => {
                    let idsArr = [];
                    for (let subject of allSubjects) {
                        idsArr.push(subject.socialSecurityNumber);
                    }
                    response.data = response.data.filter(x => !idsArr.includes(x.socialSecurityNumber));
                    if (response.data && response.data.length > 0) {
                        // response.data = filteredData;
                        // ok there are subjects for removal
                        resolve("/org/dash/subjectUpload/Removal");
                        dispatch({
                            type: REMOVE_PERSONS,
                            payload: response
                        });
                    } else {
                        resolve("/org/dash/subject");
                        dispatch({
                            type: CREATE_PERSONS,
                            payload: response
                        });
                    }
                }).catch(error => {
                    switch (error.response.status) {
                        case 500:
                            reject(error.response.data.message);
                            break;
                    }
                });
            });
        }
    }

}

