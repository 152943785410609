/**
 * Created by shivaraman on 5/10/17.
 */
import axios from 'axios';
import {addTokenHeader, refreshToken, TOKEN_KEY} from './auth_actions';
import {CUR_ORG} from "./session_constants";

// To get the configuration so we can load properties
const Config = require('Config');

export async function fetchAssets() {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/asset/get`;

        const response = await axios.get(reqUrl, addTokenHeader());
        console.log('Asset Response is ', response);
        return response.data;
    }
}

export async function fetchAsset(assetId) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/asset/${assetId}/get`;

        const response = await axios.get(reqUrl, addTokenHeader());
        console.log('Asset Response is ', response);
        return response.data;
    }
}

export async function createAsset(asset){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        asset.orgId = sessionStorage.getItem(CUR_ORG);
        const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/asset/create`;

        const response = await axios.post(reqUrl, asset, addTokenHeader());
        console.log('Asset creation response is ', response);
        return response.data;
    }
}

export async function updateAsset(asset){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/asset/update`;

        const response = await axios.post(reqUrl, asset, addTokenHeader());
        return response.data;
    }
}

export async function checkoutAsset(asset){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        asset.orgId = sessionStorage.getItem(CUR_ORG);
        const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/asset/checkout`;

        const response = await axios.post(reqUrl, asset, addTokenHeader());
        console.log('Asset checkout response is ', response);
        return response.data;
    }
}

export async function bulkUploadAsset(assets){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        for (let asset of assets){
            asset.orgId = sessionStorage.getItem(CUR_ORG);
        }
        const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/asset/bulkUpload`;

        const response = await axios.post(reqUrl, assets, addTokenHeader());
        console.log('Asset bulk upload response is ', response);
        return response.data;
    }
}

export async function checkinAsset(asset){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        asset.orgId = sessionStorage.getItem(CUR_ORG);
        const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/asset/checkin`;

        const response = await axios.post(reqUrl, asset, addTokenHeader());
        console.log('Asset checkin response is ', response);
        return response.data;
    }
}