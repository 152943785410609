import axios from 'axios';
import {addTokenHeader, refreshToken, SUBJECT_ID, TOKEN_KEY} from './auth_actions';
import {CUR_ORG} from "./session_constants";
import {getDocDetail} from "./doc_actions";

// To get the configuration so we can load properties
const Config = require('Config');

export async function fetchClearancesForSubject(subjectId) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/clearance/bySubject/${subjectId}`;

        const response = await axios.get(reqUrl, addTokenHeader());
        return response.data;
    }
}

export async function createClearance(clearance){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/clearance/create`;

        const response = await axios.post(reqUrl, clearance, addTokenHeader());
        return response.data;
    }
}

export async function updateClearance(clearance){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/clearance/${clearance.id}/update`;

        const response = await axios.post(reqUrl, clearance, addTokenHeader());
        return response.data;
    }
}

export async function debriefClearance(debriefInfo, clearanceId){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        if (debriefInfo.documents && Array.from(debriefInfo.documents).length === 1){
            let doc = Array.from(debriefInfo.documents)[0];
            console.log('Got preview ', doc)
            const docDataResp = await getDocDetail(doc.preview);
            console.log('Got docData ', docDataResp)
            doc.revisions = [{
                uploader: sessionStorage.getItem(SUBJECT_ID),
                fileName: doc.name,
                fileType: doc.type,
                comments: doc.comments,
                effectiveDate: doc.effectiveDate,
                data: new Buffer(docDataResp.data, 'binary').toString('base64')
            }];
            debriefInfo.document = doc;
        }
        const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/clearance/${clearanceId}/debrief`;

        const response = await axios.post(reqUrl, debriefInfo, addTokenHeader());
        return response.data;
    }
}

export async function deleteClearance(clearanceId){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const reqUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/clearance/${clearanceId}/delete`;

        const response = await axios.delete(reqUrl, addTokenHeader());
        return response.data;
    }
}

