/**
 * Created by pshivaraman on 4/15/19.
 */
import axios from 'axios';
import {
    addTokenHeader,
    addWithCredentials,
    refreshToken,
    REPORTING_PASS_CODE,
    SUBJECT_ID,
    TOKEN_KEY, VERIFIED_EMAIL
} from "./auth_actions";
import {
    CONTACT_EMAIL_SENT,
    CREATE_DOCUMENT,
    CREATE_TASK,
    FAILURE,
    FETCH_ORG_TASKS,
    FETCH_REPORTABLE_ACTIVITY,
    FETCH_TASK,
    FETCH_TASKS,
    MASS_EMAIL_FOR_ALL,
    MASS_EMAIL_FOR_ALL_ORG_ADMINS,
    SELF_REPORTING_DONE,
    SUBJECT_CHANGES_CONFIRMED,
    SUBJECT_REVIEW_INITIATED,
    SUCCESS,
    TASK_CANCELLED,
    TASK_COMPLETED, TASK_REJECTED, VISIT_REJECTED
} from "./action_constants";
import {getDataFromDocs, getDataFromDocsAsync, getDocDetail} from "./doc_actions";
import {CUR_ORG} from "./session_constants";
import {CommonUtils} from "./common_utils";

// To get the configuration so we can load properties
const Config = require('Config');

export async function getIssuesForOrgWithFilter(issueNameFilter = "", issueNameSort = 0,
                                                dateCreatedSort = 0,
                                                entityNameFilter = "", entityNameSort = 0,
                                                entityTypeFilter = ["ORG"], entityTypeSort = 0,
                                                issueStatusFilter = ["ACTIVE"], issueStatusSort = 0,
                                                workflowStatusFilter = ["PENDING"], workflowStatusSort = 0,
                                                includeSubOrgs = false, orgNameSort = 0,
                                                page = -1, sizePerPage = -1) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)){
        const reqUrl = `${Config.taskServerUrl}/issue/byOrg/${sessionStorage.getItem(CUR_ORG)}/withEntity/byQuery`;

        const queryObj = {
            issueNameFilter: issueNameFilter,
            includeSubOrgs: includeSubOrgs,
            entityNameFilter: entityNameFilter,
            entityTypeFilter: entityTypeFilter,
            issueStatusFilter: issueStatusFilter,
            workflowStatusFilter: workflowStatusFilter,
            dateCreatedSort: dateCreatedSort,
            issueNameSort: issueNameSort,
            entityNameSort: entityNameSort,
            entityTypeSort: entityTypeSort,
            issueStatusSort: issueStatusSort,
            workflowStatusSort: workflowStatusSort,
            orgNameSort: orgNameSort,
            page: page,
            sizePerPage: sizePerPage
        }

        console.log('Query Obj is ', queryObj)
        const response = await axios.post(reqUrl, queryObj, addTokenHeader());
        console.log('Response is ', response);
        return response.data;
    }
    return [];
}

export function fetchIssuesWithEntityForOrg(orgId = sessionStorage.getItem(CUR_ORG), issueName = "", entityName = "",
                                            entityTypes = ["ORG"], issueStatus = ["ACTIVE"], workflowStatus = ["PENDING"], includeSubOrgs = false) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const reqUrl = `${Config.taskServerUrl}/issue/byOrg/${orgId}/withEntity/byQuery`;

            const queryObj = {
                issueNameLike: issueName,
                includeSubOrgs: includeSubOrgs,
                entityNameLike: entityName,
                entityTypes: entityTypes,
                issueStatusList: issueStatus,
                workflowStatusList: workflowStatus
            }
            console.log('Query Obj is ', queryObj)
            axios.post(reqUrl, queryObj, addTokenHeader()).then((response) => {
                console.log('Response is ', response);
                dispatch({
                    type: FETCH_ORG_TASKS,
                    payload: response
                })
            });
        }
    }
}

export function fetchTaskChildren(id) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.taskServerUrl}/issue/${id}/getChildren`;

            axios.get(url, addTokenHeader()).then((response) => {
                dispatch({
                    type: FETCH_TASKS,
                    payload: response
                });
            });
        }
    }
}

export async function fetchReportableActivity(id) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/person/reportableActivity/${id}`;

        const response = await axios.get(url, addTokenHeader());
        return response.data;
    }
}

export async function fetchPriorPassportInfoIfAny() {
    // Set reportable information
    let req = {};
    req.passCode = sessionStorage.getItem(REPORTING_PASS_CODE);
    req.emailAddress = sessionStorage.getItem(VERIFIED_EMAIL);

    const url = `${Config.taskServerUrl}/subject/selfReporting/getPriorPassportInfo`;

    const response = await axios.post(url, req, addWithCredentials());
    return response.data;
}

export function rejectTask(task, taskId, onSuccess, onError) {
    // Return an asynchronous function with the redux dispatch function.
    return async (dispatch) => {
        refreshToken(dispatch);
        const url = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/task/${taskId}/reject`;
        console.log('Rejection url ', url);

        // Catch server errors
        try {
            const response = await axios.post(url, task, addTokenHeader());
            onSuccess();
            // Redux dispatch
            dispatch({
                type: TASK_REJECTED,
                payload: response
            });
            return response.data;
        } catch (error) {
            onError(error);
        }
    };
}

export function approveTask(taskId, onSuccess, onError) {
    // Return an asynchronous function with the redux dispatch function.
    return async (dispatch) => {
        refreshToken(dispatch);
        const url = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/task/${taskId}/approve`;

        // Catch server errors
        try {
            const response = await axios.post(url, {}, addTokenHeader());
            onSuccess();
            // Redux dispatch
            dispatch({
                type: TASK_COMPLETED,
                payload: response
            });
            return response.data;
        } catch (error) {
            onError(error);
        }
    };
}

export async function fetchTaskByHashcode(hashCode) {
    const reqUrl = `${Config.taskServerUrl}/subject/selfReporting/issueByEmailHash/${hashCode}`;

    const response = await axios.get(reqUrl);
    return response.data;
}

export async function fetchTaskByHashcodeForSecondFactor(hashCode) {
    const reqUrl = `${Config.taskServerUrl}/subject/selfReporting/issueByEmailHash/${hashCode}/forSecondFactor`;

    const response = await axios.get(reqUrl);
    return response.data;
}

export async function fetchAllUploadTasksForSubject() {
    let selfReport = {
        passCode: sessionStorage.getItem(REPORTING_PASS_CODE),
        emailAddress: sessionStorage.getItem(VERIFIED_EMAIL)
    }
    const reqUrl = `${Config.taskServerUrl}/subject/selfReporting/actionableIssues/byOrg/forSubject`;

    const response = await axios.post(reqUrl, selfReport, addWithCredentials());
    return response.data;
}

export function fetchTask(id) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.taskServerUrl}/issue/${id}`;

            axios.get(url, addTokenHeader()).then((response) => {
                dispatch({
                    type: FETCH_TASK,
                    payload: response
                });
            });
        }
    }
}

export async function fetchTaskAsync(id) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.taskServerUrl}/issue/${id}`;

        const response = await axios.get(url, addTokenHeader());
        return response.data;
    }
}

export async function fetchReportableActivityAsync(id) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/person/reportableActivity/${id}`;

        const response = await axios.get(url, addTokenHeader());
        return response.data;
    }
}

export function resetTaskEmailStatus(id, callBack = () => {
}) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.taskServerUrl}/issue/${id}/resetEmail`;

            axios.get(url, addTokenHeader()).then((response) => {
                callBack();
            });
        }
    }
}

export function resendPostTravelQuestionnaireReminder(id, callBack = () => {
}) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            console.log('Resending email');
            const url = `${Config.taskServerUrl}/issue/${id}/resendPostTravelReminder`;

            axios.get(url, addTokenHeader()).then((response) => {
                callBack();
            });
        }
    }
}

export function sendContactEmail(contactForm) {
    return function (dispatch) {

        return new Promise((resolve, reject) => {
            const url = `${Config.personServerUrl}/person/contactUs`;

            axios.post(url, contactForm).then((response) => {
                resolve(response.data);

                dispatch({
                    type: CONTACT_EMAIL_SENT,
                    payload: response
                })
            }).catch(reject);
        });
    }
}

export function cancelTask(taskId, callback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            let orgId = sessionStorage.getItem(CUR_ORG);
            const reqUrl = `${Config.taskServerUrl}/org/${orgId}/task/${taskId}/cancel`;

            axios.post(reqUrl, {}, addTokenHeader()).then((response) => {
                callback();
                dispatch({
                    type: TASK_CANCELLED,
                    payload: response
                })
            });
        }
    }
}

export function completeTask(taskId, callback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            let orgId = sessionStorage.getItem(CUR_ORG);
            const reqUrl = `${Config.taskServerUrl}/org/${orgId}/task/${taskId}/complete`;

            axios.post(reqUrl, {}, addTokenHeader()).then((response) => {
                callback();
                dispatch({
                    type: TASK_COMPLETED,
                    payload: response
                })
            });
        }
    }
}

/**
 * Creates an email task for Org
 * @param task task object
 * @param callback callback on success
 * @param isSysEmailTask default false
 */
export function createEmailTask(task, callback, errorCallback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {

            getDataFromDocs(task.userInstructionDocs, (docs) => {
                task.documents = docs.map(doc => {
                    return {
                        document: {
                            name: doc.name,
                            type: "OTHER"
                        },
                        revisions: [
                            {
                                fileName: doc.name,
                                fileType: doc.type,
                                data: doc.data,
                                uploader: sessionStorage.getItem(SUBJECT_ID)
                            }
                        ]
                    };
                });

                // console.log(task);
                let url = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/createEmailTask`;
                if (task.broadcastEmailType) {
                    if (MASS_EMAIL_FOR_ALL === task.broadcastEmailType) {
                        url = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/createOrgAdminEmailTask/forAll`;
                    } else if (MASS_EMAIL_FOR_ALL_ORG_ADMINS === task.broadcastEmailType) {
                        url = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/createOrgAdminEmailTask`;
                    }
                }

                axios.post(url, task, addTokenHeader()).then((response) => {
                    callback(response);
                    dispatch({
                        type: CREATE_TASK,
                        payload: response
                    });
                }).catch(errorCallback);
            });
        }
    }
}

export async function updateEmailTask(task) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        let url = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/subjectTask/update`;
        const response = await axios.post(url, task, addTokenHeader());
        return response;
    }
}

export async function createEmailTaskPrecheck(task, onSuccess, errorCallback) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        let url = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/createEmailTask/preCheck`;
        console.log('Doing precheck');
        try {
            await axios.post(url, task, addTokenHeader());
            onSuccess();
        } catch (error) {
            errorCallback(error);
        }
    }
}

export async function uploadDocumentForTask(emailCode, files) {
    let docsSubmitForTask = [];
    if (files && files.length > 0) {
        const docs = await getDataFromDocsAsync(files);
        docsSubmitForTask = CommonUtils.createDocForUploadingForFormik(docs);
    }
    const url = `${Config.taskServerUrl}/subject/issue/${emailCode}/doc/add`;
    const response = await axios.post(url, docsSubmitForTask, addTokenHeader());
    return response.data;
}

export function reportPostForeignTravel(postForeignTravel, emailCode, callback) {
    return function (dispatch) {
        const url = `${Config.taskServerUrl}/subject/selfReporting/reportPostTravel/${emailCode}`;
        console.log('Submitting foreign travel response', url, postForeignTravel)
        axios.post(url, postForeignTravel, addWithCredentials()).then((response) => {
            callback(SUCCESS);
            dispatch({
                type: SUBJECT_CHANGES_CONFIRMED,
                payload: response
            });
        }).catch((error) => {
            console.log('Error confirming subject changes', error)
            callback(FAILURE);
        });
    }
}


export function confirmSubjectChanges(emailCode, changeType, callback) {
    return function (dispatch) {
        const url = `${Config.taskServerUrl}/subject/selfReporting/${emailCode}/confirmChanges/${changeType}`;
        axios.post(url, addTokenHeader()).then(response => {
            callback(SUCCESS);

            dispatch({
                type: SUBJECT_CHANGES_CONFIRMED,
                payload: response
            });
        }).catch((error) => {
            console.log('Error confirming subject changes', error)
            callback(FAILURE);
        });
    }
}