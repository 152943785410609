/**
 * Created by pshivaraman on 7/27/18.
 */
import {SHOW_MESSAGE} from "./action_constants";

export function showGrowlMessages(messages) {
    return function (dispatch) {
        dispatch({
            type: SHOW_MESSAGE,
            payload: messages
        })
    }
}

export function showGrowlMessage(message){
    return showGrowlMessages([message]);
}

export function showGrowl(summary, detail = null, severity = "", closeable = true, life = 3000, sticky = false, icon = null) {
    return showGrowlMessage({summary, detail, severity, closeable, life, sticky, icon});
}

export function showSuccessGrowl(summary, detail = null, closeable = true, life = 3000, sticky = false, icon = "check"){
    return showGrowlMessage({summary, detail, severity: "success", closeable, life, sticky, icon});
}

export function showWarnGrowl(summary, detail = null, closeable = true, life = 3000, sticky = false, icon = "exclamation-triangle"){
    return showGrowlMessages({summary, detail, severity: "warning", closeable, life, sticky, icon});
}

export function showErrorGrowl(summary, detail = null, closeable = true, life = 3000, sticky = false, icon = "times"){
    return showGrowlMessage({summary, detail, severity: "danger", closeable, life, sticky, icon});
}

export function showInfoGrowl(summary, detail = null, closeable = true, life = 3000, sticky = false, icon = "info-circle"){
    return showGrowlMessage({summary, detail, severity: "info", closeable, life, sticky, icon});
}

