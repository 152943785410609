import axios from "axios";
import {CHATBOT_TOKEN, TOKEN_KEY} from "./auth_actions";

const CHATBOT_BASE_URL = "https://fsochatapi.azurewebsites.net";

// Axios Chatbot Instance
export const axiosChatbot = axios.create({
    baseURL: CHATBOT_BASE_URL
});

axiosChatbot.interceptors.request.use(
    config => {
        config.headers["Authorization"] = sessionStorage.getItem(CHATBOT_TOKEN)
        return config;
    }
);

axiosChatbot.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const originalRequest = error.config;
    // For some reason chatbot API throws a 500 when token is invalid
    if ((error.response.status === 401 || error.response.status === 500) && !originalRequest._retry) {
        originalRequest._retry = true;

        // Refresh Token
        try {
            console.log("Failed, refreshing chatbot token.");
            await loginChatbot("headlessAPI");
            axiosChatbot.defaults.headers.common["Authorization"] = sessionStorage.getItem(CHATBOT_TOKEN);

            return axiosChatbot(originalRequest);
        } catch (_e) {
            // Clear out authentication since token doesn't work
            sessionStorage.removeItem(CHATBOT_TOKEN);

            return Promise.reject("CHATBOT_TOKEN_EXPIRED");
        }
    }

    return Promise.reject(error);
});

export const queryChatbot = async (query, documentType = "FSO Toolkit", history = []) => {
    if (!sessionStorage.getItem(CHATBOT_TOKEN)){
        // Login
        await loginChatbot("headlessAPI");
    }

    const url = `/query`;
    const response = await axiosChatbot.post(url, {
        query,
        documentType,
        history
    }, {
        headers: {
            username: "headlessAPI"
        }
    });

    return response.data;
};

const loginChatbot = async (username) => {
    /*const url = `${CHATBOT_BASE_URL}/truVetting/chatbot/login`;
    const response = await axios.post(url, {username});
    if (response && response.data){
        sessionStorage.setItem(CHATBOT_TOKEN, response.data.token);
    }*/
    sessionStorage.setItem(CHATBOT_TOKEN, "madeuptok3n4h3adLe$ap1"); // TODO: This may need to be changed
}