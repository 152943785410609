/**
 * Created by shivaraman on 7/5/17.
 */

import axios from 'axios';
import {addTokenHeader, addWithCredentials, refreshToken, SUBJECT_ID, TOKEN_KEY} from "./auth_actions";
import {
    BEGIN_BULK_DOC_UPLOAD,
    CREATE_DOCUMENT,
    DELETE_DOCUMENT,
    DOCS_BULK_UPLOADED, DOCUMENT_UPDATED,
    FETCH_DOCUMENT,
    FETCH_DOCUMENTS,
    SEARCH_DOCUMENTS
} from "./action_constants";
import {CUR_ORG} from "./session_constants";
import {getEmailAuthCreds} from "./subject_actions";

// To get the configuration so we can load properties
const Config = require('Config')

export function fetchOrgDocuments() {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/doc/byQuery`;

            axios.post(url, {}, addTokenHeader()).then(response => {
                dispatch({
                    type: FETCH_DOCUMENTS,
                    payload: response
                });
            });
        }
    }
}

export function fetchDocumentsUploadedForTask(taskId) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/task/${taskId}/docs`;

            axios.get(url, addTokenHeader()).then((response) => {
                console.log('Response is ', response)
                dispatch({
                    type: FETCH_DOCUMENTS,
                    payload: response
                });
            }).catch(e => {
                if (e.response.status === '404'){
                    dispatch({
                        type: FETCH_DOCUMENTS,
                        payload: []
                    });
                }
            });
        }
    }
}

export async function fetchDocumentsUploadedForTaskAsync(taskId) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/task/${taskId}/docs`;

        const response = await axios.get(url, addTokenHeader());
        return response.data;
    }
}

export function fetchDocumentsByQuery(entityType, entityId, nameFilter = "", typeFilter = null, uploaderNameFilter = "",
                                      commentsFilter = "",
                                      nameSort = 0, typeSort = 0, effectiveDateSort = 0, uploaderNameSort = 0,
                                      page = -1, sizePerPage = -1) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/doc/byQuery`;

            const queryObj = {
                entityType,
                entityId,
                nameFilter,
                typeFilter,
                uploaderNameFilter,
                commentsFilter,
                nameSort,
                typeSort,
                effectiveDateSort: effectiveDateSort,
                uploaderNameSort,
                page,
                sizePerPage
            };

            console.log('Query Obj is ', queryObj)
            axios.post(url, queryObj, addTokenHeader()).then((response) => {
                console.log('Response is ', response)
                dispatch({
                    type: FETCH_DOCUMENTS,
                    payload: response
                });
            }).catch(e => {
                dispatch({
                    type: FETCH_DOCUMENTS,
                    payload: {data: []}
                });
            });
        }
    }
}

export function fetchDocumentsByQueryNotIncluding(entityType, entityId, nameFilter = "", typeFilter = null, uploaderNameFilter = "",
                                                  nameSort = 0, typeSort = 0, dateUploadedSort = 0, uploaderNameSort = 0,
                                                  page = -1, sizePerPage = -1) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/doc/byQueryNotIncluding`;

            const queryObj = {
                entityType,
                entityId,
                nameFilter,
                typeFilter,
                uploaderNameFilter,
                nameSort,
                typeSort,
                dateUploadedSort,
                uploaderNameSort,
                page,
                sizePerPage
            };

            axios.post(url, queryObj, addTokenHeader()).then((response) => {
                dispatch({
                    type: SEARCH_DOCUMENTS,
                    payload: response
                });
            });
        }
    }
}

export async function getReqdDocsDueReport(){
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)){
        const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/reports/reqdDocsDue`;
        const response = await axios.get(url, addTokenHeader());
        return response.data;
    }
}

export function fetchDocument(docId) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/doc/${docId}`;
            axios.get(url, addTokenHeader()).then(response => {
                dispatch({
                    type: FETCH_DOCUMENT,
                    payload: response
                });
            });
        }
    }
}

export function createDocument(doc, callBack, onProgress) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/doc/create`;

            getDocDetail(doc.previewUrl).then(docDataResp => {
                doc.revisions = [{
                    uploader: sessionStorage.getItem(SUBJECT_ID),
                    fileName: doc.filename,
                    fileType: doc.filetype,
                    comments: doc.comments,
                    effectiveDate: doc.effectiveDate,
                    data: new Buffer(docDataResp.data, 'binary').toString('base64')
                }];

                const headers = {
                    headers: {
                        'Authorization': sessionStorage.getItem(TOKEN_KEY)
                    },
                    onUploadProgress: (progressEvent) => {
                        onProgress(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
                    }
                }

                axios.post(url, doc, headers).then(response => {
                    // ToDo Revoke the preview url so memory is cleared
                    callBack(response.data.id);
                    dispatch({
                        type: CREATE_DOCUMENT,
                        payload: response
                    });
                })
            })
        }
    }
}

export function beginBulkUploadForDocs() {
    return function (dispatch) {
        dispatch({
            type: BEGIN_BULK_DOC_UPLOAD
        });
    }
}

export function createOneOfManyDocuments(file, onProgress) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const precheckUrl = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/doc/create/fromName/precheck`;
            let doc = {};
            doc.name = file.name;
            axios.post(precheckUrl, doc, addTokenHeader()).then(response => {
                if (response.data && response.data.errorMsg) {
                    dispatch({
                        type: DOCS_BULK_UPLOADED,
                        payload: response
                    });
                } else {
                    const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/doc/create/fromName`;

                    getDocDetail(file.preview).then(docDataResp => {
                        doc.revisions = [{
                            uploader: sessionStorage.getItem(SUBJECT_ID),
                            fileName: file.name,
                            fileType: file.type,
                            data: new Buffer(docDataResp.data, 'binary').toString('base64')
                        }];

                        const headers = {
                            headers: {
                                'Authorization': sessionStorage.getItem(TOKEN_KEY)
                            },
                            onUploadProgress: (progressEvent) => {
                                onProgress(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)), doc.name);
                            }
                        }

                        axios.post(url, doc, headers).then(response => {
                            window.URL.revokeObjectURL(file.preview);
                            console.log('Bulk uploa dresponse is ', response);
                            dispatch({
                                type: DOCS_BULK_UPLOADED,
                                payload: response
                            });
                        })
                    })
                }
            });

        }
    }
}

export function addDocToEntity(docId, entityType, entityId, callBack) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/doc/${docId}/addToEntity`;
            const entityRel = {
                entityType,
                entityId,
                deleted: false
            };

            axios.post(url, entityRel, addTokenHeader()).then((response) => {
                // Call the task service to run the relevant timers
                const taskUrl = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/doc/checkIssues`;
                axios.post(taskUrl, entityRel, addTokenHeader()).then(); // Dont care for this return
                callBack();
                dispatch({
                    type: CREATE_DOCUMENT,
                    payload: response
                });
            });
        }
    }
}

export function addDocsToEntity(docIds, entityType, entityId, callBack) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/doc/addMultipleToEntity`;
            const requestObj = {
                docIds: docIds,
                rel: {
                    entityType,
                    entityId,
                    deleted: false
                }
            };

            axios.post(url, requestObj, addTokenHeader()).then((response) => {
                const taskUrl = `${Config.taskServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/doc/checkIssues`;
                axios.post(taskUrl, requestObj.rel, addTokenHeader()).then(); // Dont care for this return
                callBack();
            });
        }
    }
}

export function removeDocFromEntity(docId, entityType, entityId, callBack) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/doc/${docId}/removeFromEntity`;
            const entityRel = {
                entityType,
                entityId,
                deleted: true
            };

            axios.post(url, entityRel, addTokenHeader()).then((response) => {
                callBack();
                dispatch({
                    type: CREATE_DOCUMENT,
                    payload: response
                });
            });
        }
    }
}

export function addRevisionToDoc(docId, doc, callBack, onProgress) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/doc/${docId}/addRevision`;

            getDocDetail(doc.previewUrl).then(docDataResp => {
                const revision = {
                    uploader: sessionStorage.getItem(SUBJECT_ID),
                    fileName: doc.filename,
                    fileType: doc.filetype,
                    comments: doc.comments,
                    effectiveDate: doc.effectiveDate,
                    data: new Buffer(docDataResp.data, 'binary').toString('base64')
                };

                const headers = {
                    headers: {
                        'Authorization': sessionStorage.getItem(TOKEN_KEY)
                    },
                    onUploadProgress: (progressEvent) => {
                        onProgress(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
                    }
                }

                axios.post(url, revision, headers).then(response => {
                    callBack();
                    dispatch({
                        type: FETCH_DOCUMENT,
                        payload: response
                    });
                })
            })
        }
    }
}

export function updateDocumentMetadata(doc, callBack, onError) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/doc/${doc.id}/updateMetadata`;

            axios.post(url, doc, addTokenHeader()).then(response => {
                callBack();
                dispatch({
                    type: DOCUMENT_UPDATED,
                    payload: response
                });
            }).catch(onError)
        }
    }
}

export async function dumpAllDocumentsOfAnOrg(orgId) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const reqUrl = `${Config.personServerUrl}/org/${orgId}/dumpDocs`;

        const response = await axios.post(reqUrl, {}, addTokenHeader());
        return response.data;
    }
}

export function deleteDocument(docId, callBack) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/doc/${docId}/delete`;
            axios.delete(url, addTokenHeader()).then(response => {
                callBack();
                dispatch({
                    type: DELETE_DOCUMENT,
                    payload: response
                })
            })
        }
    }
}

export async function getDataFromDocsAsync(docArray) {
    let newDocArray = [],
        promises = [],
        urlMap = {};
    if (!docArray) {
        return newDocArray;
    }

    // Collecting all the promises
    docArray.forEach((doc) => {
        if (doc.preview) { // If it's a new document
            promises.push(getDocDetail(doc.preview));
            urlMap[doc.preview] = doc;
        } else if (doc.docId) { // If it's an existing document
            newDocArray.push(doc);
        }
    });

    // Now from all collected promises get the actual data and store
    const results = await axios.all(promises);
    results.forEach((response) => {
        const document = urlMap[response.config.url];
        let newDoc = {
            name: document.name,
            type: document.type,
            data: new Buffer(response.data, 'binary').toString('base64')
        }
        if (document.docType){
            newDoc.docType = document.docType;
        }
        newDocArray.push(newDoc);
        // Do this so the temp preview doesnt occupy memory and cause leaks
        window.URL.revokeObjectURL(document.preview);
    });
    // This is the one that calls the backend after the documents from the page are loaded
    return newDocArray;
}

export function getDataFromDocs(docArray, callBack) {
    getDataFromDocsAsync(docArray).then(callBack);
}

// Use the preview url to get the document data from preview
export function getDocDetail(previewUrl) {
    const config = {
        responseType: 'arraybuffer'
    };
    return axios.get(previewUrl, config);
}

function processDownload(response) {
// Get the Document Data
    const data = response.data;
    // Create hidden anchor tag
    let a = document.createElement("a");
    // Convert base64 to byte array
    const byteCharacters = atob(data);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    // Create a URL to download blob data.
    const blob = new Blob([byteArray], {type: null}),
        url = window.URL.createObjectURL(blob);
    // Add tag to body
    document.body.appendChild(a);
    // Hide the anchor tag
    a.style = "display: none";
    // Set URL and FileName
    a.href = url;
    a.download = response.headers.filename ? response.headers.filename : "unknown.txt";
    // Trigger the anchor tag for browser download
    a.click();
    // Remove the blob url to conserve memory
    window.URL.revokeObjectURL(url);
    // Delete the anchor tag.
    document.body.removeChild(a);
}

export function downloadDocumentForSelfReporting(awsS3Key, emailHashCode) {
    // go to smo service and get the document's data
    const url = `${Config.taskServerUrl}/subject/selfReporting/task/${emailHashCode}/doc/download/` + encodeURIComponent(awsS3Key);

    axios.get(url, addWithCredentials()).then(response => {

        processDownload(response);
    });
}

export function downloadDocument(awsS3Key) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        // go to smo service and get the document's data
        const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/doc/download/` + encodeURIComponent(awsS3Key);

        console.log('Url to download is ', url)
        axios.get(url, addTokenHeader()).then(response => {

            processDownload(response);
        });
    }
}

export function getDocUrl(awsS3Key) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/doc/getDataUrl/${awsS3Key}`;
        return axios.get(url, addTokenHeader());
    }
}
export async function getLocComplianceDoc(docData) {
    refreshToken();
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/doc/facilities/byDocType`;

        const response = await axios.post(url, docData, addTokenHeader());
        console.log('Response for facDocType is ', response.data)
        return response.data;
    }
}