/**
 * Created by pshivaraman on 6/7/18.
 */

import {addTokenHeader, refreshToken, TOKEN_KEY} from "./auth_actions";
import {onError} from "./error_actions";
import axios from "axios";
import {getDataFromDocs} from "./doc_actions";
import {
    CREATE_SMO,
    FETCH_ORG_FACILITY,
    FETCH_SMOS,
    FETCH_SMOS_BY_ORG,
    SEARCH_FACILITIES,
    UPDATE_ORG_FACILITY
} from "./action_constants";
import {CUR_ORG} from "./session_constants";
import {getActiveOrgPersons} from "./subject_actions";
import {getFacilityLevels, getTimezones} from "./fieldValue_actions";
import {getOrgContracts} from "./contract_actions";

// To get the configuration so we can load properties
const Config = require('Config');

/**
 * Extracts underlying Facility from FacilityUI and combines the two.
 * @param facility FacilityUI object from axios
 * @returns {*} Combined Facility and FacilityUI
 */
export function getFacilityObj(facility) {
    if (facility) {
        return facility.facility ? {...facility.facility, ...facility} : facility;
    }
    return null;
}

export function createFacility(orgId, facility, callBack) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${orgId}/facility/create`;
            axios.post(url, facility, addTokenHeader()).then((response) => {
                callBack();

                dispatch({
                    type: CREATE_SMO,
                    payload: response
                });
            }); // when promise returns then we call the callback
        }
    }
}

// /person-api/org/{orgId}/contract/{contractId}/facilities/{facilityId}/remove

export function removeFacilityFromContract(contractId, facilityId, callBack) {
    console.log(`Facility ${facilityId} is being removed from contract ${contractId}`)
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/contract/${contractId}/facilities/${facilityId}/remove`;
            axios.delete(url, addTokenHeader()).then(response => {
                callBack()
            })
        }
    }
}

export function deleteFacility(facilityId, callBack) {
    console.log(`Facility ${facilityId} is going to be deleted`)
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/facility/${facilityId}/remove`;
            console.log(`url for delete is ${url}`)
            axios.delete(url, addTokenHeader()).then(response => {
                callBack()
            })
        }
    }
}

export function fetchOrgFacilities(orgId) {
    return function (dispatch) {
        refreshToken(dispatch);
        getOrgFacilities(orgId).then((response) => {
            const facilities = response.data.map(facility => {
                return {...facility, ...facility.facility};
            });
            dispatch({
                type: FETCH_SMOS_BY_ORG,
                payload: {
                    data: facilities
                }
            });
        });
    }
}

export function getOrgFacilities(orgId) {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        const url = `${Config.personServerUrl}/org/${orgId}/facility/list`;
        return axios.get(url, addTokenHeader());
    }
}

export function fetchOrgFacility(facilityId) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/facility/${facilityId}`;
            axios.get(url, addTokenHeader()).then(response => {
                const facility = response.data;
                getActiveOrgPersons(personsResp => {
                    facility.avail_subjects = personsResp.data;

                    getOrgContracts(sessionStorage.getItem(CUR_ORG)).then(contractsResp => {
                        facility.avail_contracts = contractsResp.data;

                        getFacilityLevels(levelsResp => {
                            facility.levels = levelsResp.data;

                            getTimezones(tzResp => {
                                facility.timezones = tzResp.data.map(tz => {
                                    return {
                                        id: tz.id,
                                        name: tz.text
                                    };
                                });

                                response.data = facility;
                                dispatch({
                                    type: FETCH_ORG_FACILITY,
                                    payload: response
                                });
                            });
                        });
                    });
                });
            });
        }
    }
}

export function updateOrgFacility(facility, facilityId, callback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/facility/update`;

            facility.id = facilityId;

            getDataFromDocs(facility.documents, (docs) => {
                facility.documents = docs;
                axios.post(url, facility, addTokenHeader()).then(response => {
                    // make a call to run the issue timers
                    const taskUrl = `${Config.taskServerUrl}/org/facility/update`
                    axios.post(taskUrl, facility, addTokenHeader()).then(resp => {
                        callback();
                        dispatch({
                            type: UPDATE_ORG_FACILITY,
                            payload: response
                        })
                    })
                })
            });
        }
    }
}

export function searchFacilitiesNotActivelyPartOfContract(contractId, searchTerm, callback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/facility/list/searchNotActivelyPartOfContract/${contractId}/${searchTerm}`;
            axios.get(url, addTokenHeader()).then((response) => {
                callback();
                dispatch({
                    type: SEARCH_FACILITIES,
                    payload: response
                });
            });
        }
    }
}

export function fetchByContractIdAndActiveFor(contractId) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/facility/list/byContractIdAndActiveFor/${contractId}`;
            axios.get(url, addTokenHeader()).then((response) => {
                dispatch({
                    type: FETCH_SMOS,
                    payload: response
                });
            });
        }
    }
}

export function addContractToFacilities(contractId, facilities, callback) {
    return function (dispatch) {
        refreshToken(dispatch);
        if (sessionStorage.getItem(TOKEN_KEY)) {
            console.log('Adding facilities')
            console.log(facilities)
            const url = `${Config.personServerUrl}/org/${sessionStorage.getItem(CUR_ORG)}/contract/${contractId}/facilities/add`;
            axios.post(url, facilities, addTokenHeader()).then((response) => {
                callback();
                dispatch({
                    type: SEARCH_FACILITIES,
                    payload: response
                });
            })
        }
    }
}