// Configures Axios

import {sessionTimeoutHandler, unauthHandler, unhandledExceptionHandler} from "./error_actions";
import axios from "axios";

const Config = require('Config');

const errorHandler = (error) => {
    // Handle Error
    if (error && error.config &&
        //(error.config.url.includes(Config.personServerUrl) || error.config.url.includes(Config.taskServerUrl)) &&
        !error.config.url.includes("auth/login") && !error.config.url.includes("auth/secondFactor")) {
        if (error.response && error.response.status) {
            switch (error.response.status) {
                case 401:
                    // Session Timeout
                    sessionTimeoutHandler();
                    return Promise.resolve("Session Timed Out");
                case 403:
                    // Unauthorized
                    unauthHandler();
                    return Promise.resolve("Unauthorized");
                case 400:
                case 404:
                    return Promise.reject({...error});
            }
        }

        console.log(error.response);
        // Other unhandled errors
        if (!error.response.data) {
            unhandledExceptionHandler(error);
        }
    }
    return Promise.reject({ ...error });
};

const successHandler = (response) => {
    // Handle Success
    return response
};

export function configureAxios(){
    axios.interceptors.response.use(
        response => successHandler(response),
        error => errorHandler(error)
    )
}