import axios from "axios";
import {addTokenHeader} from "./auth_actions";

const Config = require('Config')

export async function fetchRemainingSignatures(errorCallback) {
    console.log('fetching from back end')
    const url = `${Config.taskServerUrl}/signaturePurchase/get`;
    console.log(url);
    const response = await axios.get(url, addTokenHeader()).catch((error)=>errorCallback(error));
    return response.data;
}

export async function fetchPurchaseHist(errorCallback) {
    const url = `${Config.taskServerUrl}/signaturePurchase/history`;
    console.log(url);
    const response = await axios.get(url, addTokenHeader()).catch((error)=>errorCallback(error));
    console.log(response);
    return response.data;
}

